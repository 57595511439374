import React, { useContext, useState } from "react";
import { StateContext } from "../../StateContainer";
import LeadContainer from "../../ComportamentoAssistente/LeadContainer";
import { BsPersonVcard as IdentityCard } from "react-icons/bs";
import { MdArrowDropUp as Arrow } from "react-icons/md";

const FormRequestContact = ({ progetto, isPanelActive, handlePanelToggle }) => {
  const {
    collectCustomerEmail,
    setCollectCustomerEmail,
    isSectionContactFormOpen,
    setIsSectionContactFormOpen,
    windowWidth,
  } = useContext(StateContext);

  const MostraFormRichiestaContatti = () => {
    if (windowWidth >= 991) {
      setIsSectionContactFormOpen(!isSectionContactFormOpen);
      setShowFormDiContatto(!showFormDiContatto);
    } else {
      setIsSectionContactFormOpen(false);
    }
  };

  const [showFormDiContatto, setShowFormDiContatto] = useState(false);

  return (
    <>
      <div className='w-100 position-relative collapse-menu'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <IdentityCard></IdentityCard>
            <h6 className='m-0 fw-bold'>Modulo di Contatto</h6>
          </div>
          <div>
            <Arrow
              className={`fs-3   ${
                isPanelActive(3) ? "down-arrow" : "top-arrow"
              }`}
            ></Arrow>
          </div>
        </div>
        <a
          className='stretched-link'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#contactInfo'
          aria-expanded='false'
          aria-controls='contactInfo'
          onClick={() => handlePanelToggle(3)}
        ></a>
      </div>

      <div
        className={`collapse`}
        id='contactInfo'
        data-bs-parent='#accordionAspettoChat'
      >
        <div className='col-12 my-3 '>
          <label className='form-label' htmlFor='flexCheckChecked'>
            Abilita modulo di contatto
          </label>
          <div
            className='form-switch d-flex align-items-center'
            style={{ height: "42px" }}
          >
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckDisabled'
              value={collectCustomerEmail}
              checked={collectCustomerEmail ? "checked" : ""}
              onChange={() => setCollectCustomerEmail(!collectCustomerEmail)}
            />
          </div>
          <p className='text-muted my-2'>
            La compilazione dei campi è obbligatoria per i campi del modulo.
          </p>
        </div>
        <div
          className='row pt-4'
          style={{ display: collectCustomerEmail ? "" : "none" }}
        >
          <LeadContainer progetto={progetto} />
        </div>
      </div>
    </>
  );
};

export default FormRequestContact;
