import React, { useState, useEffect, useRef } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { TbDotsVertical as Dots } from "react-icons/tb";
import { IoMdSend } from "react-icons/io";

const ProvaChatbot = ({ content }) => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [response, setResponse] = useState("");
  const [jsonRasa, setJsonRasa] = useState("");
  const [informationIntent, setInformationIntent] = useState("");

  const [passEntities, setPassEntities] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  //Classificazione intent
  const [intentClassification, setIntentClassification] = useState("");

  // Inizializza lo stato iniziale della sessione.
  const [sessionId, setSessionId] = useState(null);

  // Genera un nuovo ID di sessione quando l'utente accede alla chatbot per la prima volta.
  useEffect(() => {
    if (!sessionId) {
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
      localStorage.setItem("sessionId", newSessionId);
    }
  }, [sessionId]);

  const fakeUa = require("fake-useragent");

  const [nomeDelBot, setNomeDelBot] = useState("");
  const [entName, setEntName] = useState("");
  const [sitoWeb, setSitoWeb] = useState("");
  const [headFootColor, setHeadFootColor] = useState("");
  const [bodyColor, setBodyColor] = useState("");
  const [iconUserBg, setIconUserBg] = useState("");
  const [iconUserColor, setIconUserColor] = useState("");
  const [iconBotBg, setIconBotBg] = useState("");
  const [iconBotColor, setIconBotColor] = useState("");

  useEffect(() => {
    // Ottieni l'URL corrente
    const url = new URL(window.location.href);

    // Estrai i valori dei parametri
    const nomeDelBotParam = url.searchParams.get("nomeDelBot") || "Chatbot";
    const entNameParam = url.searchParams.get("entName") || "Azienda";
    const sitoWebParam =
      url.searchParams.get("siteLink") || "http://127.0.0.1:5000/";
    const headFootColorParam =
      url.searchParams.get("headFootColor") || "#DBDBDB";
    const bodyColorParam = url.searchParams.get("bodyColor") || "white";
    const iconUserBgParam = url.searchParams.get("iconUserBg") || "#6eb3d1";
    const iconUserColorParam = url.searchParams.get("iconUserColor") || "white";
    const iconBotBgParam = url.searchParams.get("iconBotBg") || "#e4013b";
    const iconBotColorParam = url.searchParams.get("iconBotColor") || "white";

    // Imposta i valori dei parametri negli state
    setNomeDelBot(nomeDelBotParam);
    setEntName(entNameParam);
    setSitoWeb(sitoWebParam);
    setHeadFootColor(headFootColorParam);
    setBodyColor(bodyColorParam);
    setIconUserBg(iconUserBgParam);
    setIconUserColor(iconUserColorParam);
    setIconBotBg(iconBotBgParam);
    setIconBotColor(iconBotColorParam);
  }, []);

  const err502message =
    "Ci scusiamo per l'inconveniente. Il server sta attualmente riscontrando un errore 502 e stiamo lavorando per risolvere il problema il prima possibile. Ti preghiamo di riprovare più tardi. Grazie per la tua pazienza e comprensione.";

  // Rimuovi l'ID di sessione dal localStorage quando l'utente effettua il refresh della pagina.
  function handleBeforeUnload() {
    localStorage.removeItem("sessionId");
  }

  const scrollRef = useRef(null);

  // useEffect(() => {

  //     scrollRef.current.scrollIntoView({ behavior: "smooth" });

  // }, [informationIntent]);

  // Registra l'evento beforeunload per gestire il refresh della pagina.
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Genera un nuovo ID di sessione.
  function generateSessionId() {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 100000);
    return `${timestamp}-${random}`;
  }

  const [clicked, setIsClicked] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsClicked(true);
      handleSendMessage();
    }
  };

  const [totalChat, setTotalChat] = useState([]);
  let userLastMessage = "";
  const handleSendMessage = () => {
    userLastMessage = message;
    setMessage("");
    let conversation = {
      userMessage: userLastMessage,
      botMessage: "Sto pensando ...",
    };
    setTotalChat((prevTotal) => [...prevTotal, conversation]);

    fetch(`${sitoWeb}?message=${message}&sessionId=${sessionId}`, {
      headers: {
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
    })
      .then((response) => {
        conversation.botMessage = "";

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const readChunk = () => {
          return reader.read().then(({ done, value }) => {
            if (done) {
              return;
            }

            let chunk = decoder.decode(value, { stream: true });
            chunk = chunk.replace(/"/g, "");

            conversation.botMessage += chunk;
            setTotalChat((prevTotal) => [...prevTotal]); // Aggiorna lo stato di totalChat

            return readChunk();
          });
        };

        readChunk().catch((error) => {
          console.error("Errore durante la lettura dei chunk:", error);
        });
      })
      .catch((error) => {
        console.error("Errore durante la richiesta:", error);
      });
  };

  const modalBodyRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const handleScroll = () => {
    const modalBody = modalBodyRef.current;
    if (
      modalBody.scrollTop !==
      modalBody.scrollHeight - modalBody.offsetHeight
    ) {
      setIsUserScrolling(true);
    } else {
      setIsUserScrolling(false);
    }
  };

  useEffect(() => {
    const modalBody = modalBodyRef.current;
    if (!isUserScrolling) {
      modalBody.scrollTop = modalBody.scrollHeight;
    }
  }, [totalChat, isUserScrolling]);

  let url = "http://localhost:8080/connector-aim.php";
  const ButtonNavigation = async (message) => {
    let initalMessage = message.split(";");

    if (message.trim() === "") return;

    // https://engine.cognitivesearch.fulgeas.com/model/parse

    // http://localhost:5005/model/parse

    //https://cognitivesearch.fulgeas.com/get-model/abcdef

    try {
      const rasaResponse = await fetch(url, {
        method: "g",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: message,
          sessionId: sessionId,
          responseSite: sitoWeb,
        }),
      });

      if (rasaResponse.status === 502) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            userMessage: message,
            systemResponse: [
              {
                bot_text: err502message,
              },
            ],
          },
        ]);
        setMessage("");
      } else {
        const rasaData = await rasaResponse.json();
        setJsonRasa(JSON.stringify(rasaData, null, 2));
        setInformationIntent(rasaData);

        let lastMessage = message;
        setMessage("");
        const chatMessage = {
          userMessage: lastMessage,
          systemResponse: rasaData,
        };

        setChatHistory((prevChatHistory) => [...prevChatHistory, chatMessage]);

        setPassEntities(true);
        if (initalMessage[2]) {
          setPassEntities(false);
        }
      }
    } catch (error) {
      console.log(error);

      setResponse("Mi dispiace, c'è stato un errore");
    }
  };

  const [buttonClicked, setButtonClicked] = useState(false);

  const [systemResponse, setSystemResponse] = useState("");
  return (
    <div className='App'>
      <div id='layout-wrapper'>
        <Navbar />
        <Sidebar />
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='w-100 user-chat mt-4 mt-sm-0 ms-lg-1 my-3'>
                  <div className='card'>
                    <div className='p-3 px-lg-4 border-bottom'>
                      <div className='row' style={{ height: "50vh" }}>
                        <div className='col-12'>
                          <h5 className='font-size-16 mb-1 fw-bold'>
                            Carica un file o inserisci il link di una pagina web
                          </h5>
                        </div>
                        <div className='col-6'>
                          <label className='form-label' htmlFor='pass'>
                            Inserisci l'url della pagina
                          </label>
                          <input
                            type='password'
                            id='pass'
                            className='form-control form-control-lg'
                          />
                        </div>
                        <div className='col-6'>
                          <label className='form-label' htmlFor='cognome'>
                            Cognome
                          </label>
                          <input
                            type='password'
                            id='cognome'
                            className='form-control form-control-lg'
                          />
                        </div>
                        <div className='col-12 d-flex align-items-center gap-2 justify-content-end'>
                          <a href='' className='btn bg-rosso text-white'>
                            Cancella
                          </a>
                          <a href='' className='btn bg-verde text-white'>
                            Procedi
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='w-100 user-chat mt-4 mt-sm-0 ms-lg-1'>
                  <div className='card'>
                    <div className='p-3 px-lg-4 border-bottom'>
                      <div className='row'>
                        <div className='col-md-4 col-6'>
                          <h5 className='font-size-16 mb-1 fw-bold'>Chatbot</h5>
                          <p className='text-muted text-truncate mb-0'>
                            Scopri direttaente le capacità della chatbot
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className='chat-conversation py-3'
                        ref={modalBodyRef}
                        style={{ overflowY: "auto" }}
                      >
                        <ul
                          className='list-unstyled mb-0 chat-conversation-message px-3'
                          data-simplebar
                        >
                          <li>
                            <div
                              className='conversation-list'
                              style={{ maxWidth: "400px" }}
                            >
                              <div className='ctext-wrap'>
                                <div className='ctext-wrap-content'>
                                  <h5 className='font-size-14 conversation-name'>
                                    <a
                                      href='#'
                                      className='text-dark text-decoration-none'
                                    >
                                      Tailor GPT
                                    </a>{" "}
                                    <span className='d-inline-block font-size-12 text-muted ms-2'>
                                      10:00
                                    </span>
                                  </h5>
                                  <p className='mb-0'>
                                    Ciao, sono l'assistente di Fulgeas. Chiedimi
                                    pure quello che vuoi!
                                  </p>
                                </div>
                                <div className='dropdown align-self-start'>
                                  <a
                                    className='text-decoration-none text-dark'
                                    href='#'
                                    role='button'
                                    data-bs-toggle='dropdown'
                                    aria-haspopup='false'
                                    aria-expanded='false'
                                  >
                                    <Dots />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>

                          {totalChat.map((row) => {
                            return (
                              <>
                                <li className='right'>
                                  <div className='conversation-list'>
                                    <div className='ctext-wrap'>
                                      <div className='ctext-wrap-content'>
                                        <h5 className='font-size-14 conversation-name '>
                                          <a
                                            href='#'
                                            className='text-dark text-decoration-none'
                                          >
                                            Tu
                                          </a>{" "}
                                          <span className='d-inline-block font-size-12 text-muted ms-2'>
                                            10:00
                                          </span>
                                        </h5>
                                        <p className='mb-0'>
                                          {row.userMessage}
                                        </p>
                                      </div>
                                      <div className='dropdown align-self-start'>
                                        <a
                                          className='text-decoration-none text-dark'
                                          href='#'
                                          role='button'
                                          data-bs-toggle='dropdown'
                                          aria-haspopup='false'
                                          aria-expanded='false'
                                        >
                                          <Dots />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div
                                    className='conversation-list'
                                    style={{ maxWidth: "400px" }}
                                  >
                                    <div className='ctext-wrap'>
                                      <div className='ctext-wrap-content'>
                                        <h5 className='font-size-14 conversation-name'>
                                          <a
                                            href='#'
                                            className='text-dark text-decoration-none'
                                          >
                                            Tailor GPT
                                          </a>{" "}
                                          <span className='d-inline-block font-size-12 text-muted ms-2'>
                                            10:00
                                          </span>
                                        </h5>
                                        <p className='mb-0'>{row.botMessage}</p>
                                      </div>
                                      <div className='dropdown align-self-start'>
                                        <a
                                          className='text-decoration-none text-dark'
                                          href='#'
                                          role='button'
                                          data-bs-toggle='dropdown'
                                          aria-haspopup='false'
                                          aria-expanded='false'
                                        >
                                          <Dots />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    <div className='p-3 chat-input-section'>
                      <div className='row'>
                        <div className='col'>
                          <div className='position-relative'>
                            <input
                              type='text'
                              className='form-control search-bar rounded-pill'
                              placeholder={`Invia un messaggio a ${nomeDelBot}`}
                              value={message}
                              onChange={(event) =>
                                setMessage(event.target.value)
                              }
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className='col-auto'>
                          <button
                            type='submit'
                            className='btn btn-primary chat-send w-md waves-effect waves-light d-flex align-items-center justify-content-center'
                          >
                            <span
                              className='d-none d-sm-inline-block me-2'
                              onClick={handleSendMessage}
                            >
                              Invia
                            </span>{" "}
                            <IoMdSend />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvaChatbot;
