import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { StateContext } from "../StateContainer";
import SitoWebUpload from "../SezioniCaricaFile/SitoWebUpload";
import DocumentiCarica from "../SezioniCaricaFile/DocumentiCarica";
import TestoLiberoCarica from "../SezioniCaricaFile/TestoLiberoCarica";
import QuestionsAndAnswers from "../SezioniCaricaFile/QuestionsAndAnswers";
import ModificaProgetto from "../ModificaProgetto";
import EmbedSitoMobile from "./EmbedSitoMobile";
import { Link } from "react-router-dom";
import OffcavasGestioneProgetto from "../TransitionPages/OffcavasGestioneProgetto";
import { useNavigate } from "react-router-dom";

import { AiOutlineArrowLeft as BackArrow } from "react-icons/ai";
import ArchivioConversazioni from "../SezioniCaricaFile/Mobile/ArchivioConversazioni";
import ComportamentoAssistente from "../ComportamentoAssistente";
import AspettoChat from "../AspettoChat";

const ModificaProgettoMobile = ({ progetto }) => {
  let navigate = useNavigate();

  const { id } = useParams();
  const { sectionMobile, setSectionMobile, setConversationCode } =
    useContext(StateContext);

  const LinkToAssistant = () => {
    console.log("LinkToAssistant");
    setConversationCode("");
    navigate(`/progetti/modifica-progetto/${id}/test-assistente`);
  };
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12 px-0 d-flex align-items-center justify-content-between px-4'>
            <Link
              className='btn text-black fs-5 p-0'
              type='button'
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasWithBackdrop'
              aria-controls='offcanvasWithBackdrop'
            >
              <BackArrow /> Torna Indietro
            </Link>
            <button
              className='btn bg-orange text-white rounded-4 my-3 text-decoration-none text-uppercase'
              data-bs-dismiss='offcanvas'
              onClick={() => LinkToAssistant()}
            >
              Prova
            </button>
          </div>
        </div>
        <div className='row '>
          {sectionMobile === "#sitoWeb" ? (
            <>
              <div className='col-12 text-center my-4'>
                <h3 className='fw-bold text-uppercase'>Sito Web</h3>
              </div>
              <div className='col-12 p-3 bg-white shadow'>
                <SitoWebUpload />
              </div>
            </>
          ) : sectionMobile === "#documenti" ? (
            <>
              <div className='col-12 text-center my-4'>
                <h3 className='fw-bold text-uppercase'>Documenti</h3>
              </div>
              <div className='col-12 p-3 bg-white shadow'>
                <DocumentiCarica />
              </div>
            </>
          ) : sectionMobile === "#questionAndAnswers" ? (
            <>
              <div className='col-12 text-center my-4'>
                <h3 className='fw-bold text-uppercase'>Q & A</h3>
              </div>
              <div className='col-12 p-3 bg-white shadow'>
                <QuestionsAndAnswers />
              </div>
            </>
          ) : sectionMobile === "#testoLibero" ? (
            <>
              <div className='col-12 text-center my-4'>
                <h3 className='fw-bold text-uppercase'>Testo Libero</h3>
              </div>
              <div className='col-12 p-3 bg-white shadow'>
                <TestoLiberoCarica />
              </div>
            </>
          ) : sectionMobile === "#comportamentoAssistente" ? (
            <>
              <div className='col-12 text-center my-4'>
                <h3 className='fw-bold text-uppercase'>Configura Assistente</h3>
              </div>

              <ComportamentoAssistente progetto={progetto} />
            </>
          ) : sectionMobile === "#aspettoChat" ? (
            <>
              <div className='col-12 text-center my-4'>
                <h3 className='fw-bold text-uppercase'>Design e Aspetto</h3>
              </div>
              <div className='col-12'>
                <AspettoChat progetto={progetto} />
              </div>
            </>
          ) : sectionMobile === "#archivioConversazioni" ? (
            <>
              <div className='col-12 text-center my-4'>
                <h3 className='fw-bold text-uppercase'>
                  Archivio Conversazioni
                </h3>
              </div>

              <ArchivioConversazioni progetto={progetto} idProgetto={id} />
            </>
          ) : (
            <EmbedSitoMobile />
          )}
        </div>
      </div>
      <OffcavasGestioneProgetto progetto={progetto} />
    </>
  );
};

export default ModificaProgettoMobile;
