import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../../StateContainer";
import { FaPaintBrush as Pennello } from "react-icons/fa";
import { MdArrowDropUp as Arrow } from "react-icons/md";

const StylesOfTheChat = ({
  currentFileImage,
  progetto,
  AvatarPink,
  handleImageChange,
  isPanelActive,
  handlePanelToggle,
}) => {
  const {
    setColoreMessaggi,
    setPrimaryColor,
    windowWidth,
    setSecondaryColor,
    coloreMessaggi,
    primaryColor,
    secondaryColor,
  } = useContext(StateContext);

  const [showStileChat, setShowStileChat] = useState(false);
  return (
    <>
      <div className='w-100 position-relative collapse-menu'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <Pennello></Pennello>
            <h6 className='m-0 fw-bold'>Design e Aspetto Grafico</h6>
          </div>
          <div>
            <Arrow
              className={`fs-3  ${
                isPanelActive(2) ? "down-arrow" : "top-arrow"
              }`}
            ></Arrow>
          </div>
        </div>
        <a
          className='stretched-link'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#coloriChat'
          aria-expanded='false'
          aria-controls='coloriChat'
          onClick={() => handlePanelToggle(2)}
        ></a>
      </div>
      <div
        className={`collapse`}
        id='coloriChat'
        data-bs-parent='#accordionAspettoChat'
      >
        {/* Colore Testo Header */}

        <div className='col-12 py-3'>
          <div>
            <label className='form-label m-0'>Colore Testo Risposta</label>
          </div>
          <div className='d-flex align-items-center gap-2 mt-2 justify-content-start'>
            <input
              type='color'
              className='form-control form-control-color w-100'
              id='exampleColorInput'
              value={coloreMessaggi || "#ffffff"}
              style={{ height: "38px" }}
              onChange={(event) => setColoreMessaggi(event.target.value)}
              title='Colore Testo Header'
            ></input>
            <p className='m-0'>
              Colore:{" "}
              <b style={{ color: `${coloreMessaggi}` }}>{coloreMessaggi}</b>
            </p>
          </div>
        </div>

        {/* Colore Primario Chat */}

        <div className='col-12 py-3'>
          <div>
            <label className='form-label m-0'>Colore Primario</label>
          </div>
          <div className='d-flex align-items-center gap-2 mt-2 justify-content-start'>
            <input
              type='color'
              className='form-control form-control-color w-100'
              id='exampleColorInput'
              value={primaryColor || "#ffffff"}
              style={{ height: "38px" }}
              onChange={(event) => setPrimaryColor(event.target.value)}
              title='Colore Primario Chat'
            ></input>
            <p className='m-0'>
              Colore: <b style={{ color: `${primaryColor}` }}>{primaryColor}</b>
            </p>
          </div>
        </div>

        {/* Colore Secondario Chat */}

        <div className='col-12 py-3'>
          <div>
            <label className='form-label m-0'>Colore Secondario</label>
          </div>
          <div className='d-flex align-items-center gap-2 mt-2 justify-content-start'>
            <input
              type='color'
              className='form-control form-control-color w-100'
              id='exampleColorInput'
              value={secondaryColor || "#ffffff"}
              style={{ height: "38px" }}
              onChange={(event) => setSecondaryColor(event.target.value)}
              title='Colore Secondario Chat'
            ></input>
            <p className='m-0'>
              Colore:{" "}
              <b style={{ color: `${secondaryColor}` }}>{secondaryColor}</b>
            </p>
          </div>
        </div>

        {/* Icona dell'assistente Virtuale*/}

        <div className='col-12 py-3'>
          <div>
            <label htmlFor='#chatbotIcon'>Icona</label>
          </div>
          <p className='text-muted'>
            Inserisci jpg o png, dimensione max 1 MB. Il risultato migliore si
            ottiene con una immagine quadrata 512 X 512
          </p>
          <div className='d-flex align-items-center my-2 w-100 gap-3'>
            <div
              className='chatbotIcon'
              style={{
                background: `url(${
                  currentFileImage === null
                    ? (progetto[0] && progetto[0].chatbotIcon) || AvatarPink
                    : currentFileImage
                }) center center / cover no-repeat`,
              }}
            ></div>

            <input
              type='file'
              onChange={handleImageChange}
              className='mt-2'
              id='chatbotIcon'
              accept='image/png, image/jpg'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StylesOfTheChat;
