import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
import FulgeasLogo from "../img/TailorGPTOriz.svg";
import { StateContext } from "./StateContainer";
import { RiAddBoxLine as CreaProgetto } from "react-icons/ri";
import { HiUser as IconaUserSenzaImmagine } from "react-icons/hi2";
import ModalCreaProgetto from "./Modals/ModalCreaProgetto";
import "../App.css";
import axios from "axios";
import ModalLogout from "./Modals/ModalLogout";
import { BsPersonVcard as IdentityCard } from "react-icons/bs";

const Sidebar = () => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState("");
  const state = useContext(StateContext);
  const {
    nascondiSidebar,
    setNascondiSidebar,
    codiceProgetto,
    projectTitle,
    token,
    setLoading,
    userName,
    userLastname,
    userEmail,
    userAvatar,
    windowWidth,
    setWindowWidth,
    AllUserData,
  } = state;

  let mainContent = document.getElementsByClassName("main-content");
  const verticalMenu = document.querySelector("#vertical-menu"); // Assumi che l'elemento abbia l'id "vertical-menu"
  const tableContent = document.getElementsByClassName("table_content");
  const cardContent = document.getElementsByClassName("card_content");

  // Funzione per impostare il pulsante attivo
  const setActive = (button) => {
    setActiveButton(button);
  };
  useEffect(() => {
    const { innerWidth: width } = window;
    if (width < 993) {
      setNascondiSidebar(true);
    }
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const { innerWidth: width } = window;
      if (width < 993) {
        setNascondiSidebar(true);
      } else {
        setNascondiSidebar(false);
      }
    };

    // Aggiungi un listener per l'evento di ridimensionamento della finestra
    window.addEventListener("resize", updateWindowDimensions);

    // Chiamato quando il componente viene smontato per rimuovere il listener
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []); // Rimuovi l'array vuoto per far eseguire il useEffect durante ogni aggiornamento

  useEffect(() => {
    Array.from(mainContent).forEach((main) => {
      if (nascondiSidebar) {
        main.classList.add("m-0");
      } else {
        main.classList.remove("m-0");
      }
    });
  }, [nascondiSidebar]);

  const ShowSidebar = () => {
    setNascondiSidebar(!nascondiSidebar);
  };

  const { innerWidth: width } = window;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    Array.from(tableContent).forEach((main) => {
      if (windowWidth <= 600) {
        main.style.overflowX = "scroll";
        main.style.minWidth = "800px";
        main.style.width = "auto";
        main.style.whiteSpace = "no-wrap";
      }
    });
  }, [windowWidth]);

  useEffect(() => {
    Array.from(cardContent).forEach((main) => {
      if (windowWidth <= 600) {
        main.style.overflowX = "scroll";
        main.style.width = "auto";
        main.style.whiteSpace = "no-wrap";
      }
    });
  }, [windowWidth]);

  return (
    <div
      className={`vertical-menu px-2 ${
        nascondiSidebar ? "nascondiSidebar" : "mostraSidebar"
      }`}
      id='sidebarMenu'
    >
      <div
        className='d-flex align-items-center justify-content-between'
        style={{ height: "70px" }}
      >
        <Link to='/dashboard' style={{ cursor: "pointer" }}>
          <img src={FulgeasLogo} alt='' className='img-fluid fulgeas-logo' />
        </Link>

        <button
          type='button'
          className='btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn'
          onClick={ShowSidebar}
        >
          <RxHamburgerMenu></RxHamburgerMenu>
        </button>
      </div>

      <div
        data-simplebar
        className={`sidebar-menu-scroll m-0`}
        id='sidebar-menu-scroll'
      >
        <div id='sidebar-menu' className='h-100 d-grid'>
          <ul
            className='metismenu list-unstyled d-flex flex-column'
            id='side-menu'
          >
            <li className='my-3 ms-3'>
              <Link className='m-0 p-0 text-decoration-none' to='/profile'>
                <div
                  className='d-flex align-items-center gap-2 justify-content-start'
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className='profile-info m-0 fs-5'
                    style={{
                      background: `${
                        userAvatar === null
                          ? "gainsboro"
                          : `url:("${userAvatar}")`
                      }`,
                    }}
                  >
                    {userAvatar === null ? <IconaUserSenzaImmagine /> : ""}
                  </div>

                  <div className=''>
                    <div className='fw-bold userInfo'>
                      {userName} {userLastname}
                    </div>
                    <div className='userEmail'>{userEmail}</div>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                className={`text-decoration-none d-flex gap-3 align-items-center linkMenu ${
                  location.pathname === "/dashboard" ? "active" : ""
                }`}
                onClick={() => setActive("dashboard")}
              >
                <AiOutlineHome />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className='menu-title'>Progetti</li>
            <li>
              <Link
                to={"/progetti"}
                className={`text-decoration-none d-flex gap-3 linkMenu align-items-center ${
                  location.pathname === "/progetti" ? "active" : ""
                }`}
                onClick={() => setActive("elenco-progetti")}
              >
                <MdOutlineCalendarMonth />
                <span> Elenco progetti</span>
              </Link>
            </li>
            <li>
              <Link
                to={"/progetti/lead"}
                className={`text-decoration-none d-flex gap-3 linkMenu align-items-center ${
                  location.pathname === "/progetti/lead" ? "active" : ""
                }`}
                onClick={() => setActive("elenco-lead")}
              >
                <IdentityCard />
                <span> Elenco Lead</span>
              </Link>
            </li>
            <li>
              <Link
                to='/progetti'
                className={`text-decoration-none d-flex gap-3 linkMenu align-items-center `}
                type='button'
                data-bs-toggle='modal'
                data-bs-target='#exampleModal'
              >
                <CreaProgetto />
                <span> Crea progetto</span>
              </Link>
            </li>
            {/* <li className='menu-title'>Supporto</li>
            <li>
              <Link
                to={"/supporto"}
                className={`text-decoration-none d-flex gap-3 align-items-center ${
                  location.pathname === "/supporto" ? "active" : ""
                }`}
                onClick={() => setActive("richiesta-supporto")}
              >
                <FaRegUserCircle />
                <span>Richiesta di supporto</span>
              </Link>
            </li> */}

            <li>
              <a
                className={`text-decoration-none d-flex gap-3 linkMenu align-items-center text-danger`}
                data-bs-toggle='modal'
                data-bs-target='#modalLogout'
                style={{ cursor: "pointer" }}
              >
                <FiLogOut />
                <span>Logout</span>
              </a>
            </li>
          </ul>
          {/* <div>
            <ul>
              <li className='metismenu' style={{ listStyle: "none" }}>
                <b>Crediti residui:</b>{" "}
                {AllUserData.creditsLeft.toLocaleString()}
              </li>
              <li className='metismenu' style={{ listStyle: "none" }}>
                <b>Caratteri totali:</b>{" "}
                {AllUserData.charsCount.toLocaleString()}
              </li>
            </ul>
          </div> */}
        </div>
        <ModalLogout />
      </div>
    </div>
  );
};

export default Sidebar;
