import React, { useContext, useState } from "react";
import { RiQuestionnaireFill as QuestionConversation } from "react-icons/ri";
import PredefinedQueries from "../../ComportamentoAssistente/PredefinedQueries";
import { StateContext } from "../../StateContainer";
import { MdArrowDropUp as Arrow } from "react-icons/md";

const PredefinedQueriesSection = ({
  progetto,
  isPanelActive,
  handlePanelToggle,
}) => {
  const { windowWidth } = useContext(StateContext);
  const [showDomandePredefinite, setShowDomandePredefinite] = useState(false);
  return (
    <>
      <div className='w-100 position-relative collapse-menu'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <QuestionConversation></QuestionConversation>
            <h6 className='m-0 fw-bold'>Domande Predefinite</h6>
          </div>
          <div>
            <Arrow
              className={`fs-3 ${
                isPanelActive(4) ? "down-arrow" : "top-arrow"
              }`}
            ></Arrow>
          </div>
        </div>
        <a
          className='stretched-link'
          type='button'
          data-bs-toggle={`${windowWidth >= 991 ? "collapse" : "false"}`}
          data-bs-target='#predefinedQueriesSection'
          aria-expanded='false'
          aria-controls='predefinedQueriesSection'
          onClick={() => handlePanelToggle(4)}
        ></a>
      </div>
      <div
        className={`collapse `}
        id='predefinedQueriesSection'
        data-bs-parent='#accordionAspettoChat'
      >
        <div className='row'>
          <PredefinedQueries progetto={progetto} />
        </div>
      </div>
    </>
  );
};

export default PredefinedQueriesSection;
