import React from "react";
import { AiFillCloseCircle as Falso } from "react-icons/ai";

const ModalNotificationError = ({ title, message, FunctionRemoveModal }) => {
  const RemoveUpdateDataModal = () => {
    FunctionRemoveModal(false);
  };

  return (
    <div className='overlay d-flex align-items-center ' id='overlayDataModal'>
      <div className='modal' style={{ display: "block" }}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title d-flex align-items-center gap-2'>
                <Falso className='text-danger fs-3' /> {title}
              </h5>
            </div>
            <div className='modal-body'>
              <p className='m-0'>
                Si è verificato il seguente errore:
                <br />
                <b>{message}</b>
              </p>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={RemoveUpdateDataModal}
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNotificationError;
