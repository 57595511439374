import React, { useContext, useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { Link } from "react-router-dom";
import { StateContext } from "./components/StateContainer";
import axios from "axios";
import useAuth from "./UseAuth";
import RigaProgetto from "./components/RigaProgetto";
import Pagination from "./Pagination";
import ModalCreaProgetto from "./components/Modals/ModalCreaProgetto";
import { AiOutlineClose as Chiudi } from "react-icons/ai";
import CardListaProgetti from "./components/MobileComponents/CardListaProgetti";

const Progetti = () => {
  const {
    loading,
    setLoading,
    error,
    setError,
    userPlanMaxProjects,
    token,
    listOfProjects,
    setListOfProjects,
    nuovoProgetto,
    setNuovoProgetto,
    windowWidth,
    eliminaProgetto,
    setEliminaProgetto,
    setPageTitle,
  } = useContext(StateContext);

  useEffect(() => {
    setPageTitle("Progetti");
  }, []);

  useEffect(() => {
    setLoading(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/list`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setLoading(false);
        setError("");
        //get token from response
        setListOfProjects(response.data.response.projects);
        setLoading(false);
        setError("An error occurred. Please try again.");
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          //nothing
        }
      });
  }, [nuovoProgetto, eliminaProgetto]);

  const CloseDeleteWarning = () => {
    setEliminaProgetto([]);
  };

  const CloseNewProjectAlert = () => {
    setNuovoProgetto([]);
  };

  return (
    <div className='App'>
      <div id='layout-wrapper'>
        <Navbar />
        <Sidebar />
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='page-title-box d-flex align-items-center justify-content-between'>
                        <h4 className='mb-0'>Elenco Progetti</h4>

                        <div className='page-title-right'>
                          <ol className='breadcrumb m-0'>
                            <li className='breadcrumb-item'>
                              <Link
                                to='/dashboard'
                                className='text-decoration-none text-dark'
                              >
                                Home
                              </Link>
                            </li>
                            <li className='breadcrumb-item text-dark'>
                              Progetti
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <button
                    type='button'
                    className={`btn bg-green-second text-white ${
                      userPlanMaxProjects <= listOfProjects.length
                        ? "d-none"
                        : ""
                    }`}
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal'
                  >
                    + Aggiungi progetto
                  </button>
                </div>
              </div>

              {/* Tabella */}
              {eliminaProgetto.status === 200 ? (
                <div
                  className='alert alert-success mt-3 d-flex align-items-center justify-content-between'
                  role='alert'
                >
                  Il tuo progetto è stato eliminato correttamente
                  <Chiudi
                    className='fs-6'
                    onClick={CloseDeleteWarning}
                  ></Chiudi>
                </div>
              ) : (
                ""
              )}

              {nuovoProgetto.status === 200 ? (
                <div
                  className='alert alert-success mt-3 d-flex align-items-center justify-content-between'
                  role='alert'
                >
                  Il tuo progetto è stato creato correttamente
                  <Chiudi
                    className='fs-6'
                    onClick={CloseNewProjectAlert}
                  ></Chiudi>
                </div>
              ) : (
                ""
              )}

              {windowWidth > 992 ? (
                <div className='col-12'>
                  <div className='table-responsive pb-3 mt-3'>
                    <table className='table table-centered table-nowrap mb-0 w-100 table_content'>
                      <thead className='thead-progetti-home'>
                        <tr className=''>
                          <th scope='col'>NOME</th>

                          <th scope='col'>CARATTERI TOTALI</th>
                          <th scope='col'>DATA CREAZIONE</th>
                          <th scope='col'>AZIONI</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listOfProjects.length === 0 ? (
                          <>
                            <tr className='text-center border-0'>
                              <td colSpan={4} className='pt-4 pb-2 border-0'>
                                Ancora nessun progetto
                              </td>
                            </tr>
                            <tr className='text-center border-0'>
                              <td colSpan={4} className='border-0'>
                                <button
                                  type='button'
                                  className={`btn bg-green-second text-white ${
                                    userPlanMaxProjects <= listOfProjects.length
                                      ? "d-none"
                                      : ""
                                  }`}
                                  data-bs-toggle='modal'
                                  data-bs-target='#exampleModal'
                                >
                                  + Crea nuovo progetto
                                </button>
                              </td>
                            </tr>
                          </>
                        ) : (
                          listOfProjects.map((progetto, index) => {
                            return (
                              <RigaProgetto
                                progetto={progetto}
                                key={index}
                              ></RigaProgetto>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : listOfProjects.length === 0 ? (
                <div className='col-12 text-center py-3 '>
                  <p className='placeholder'>Nessun progetto caricato</p>
                </div>
              ) : (
                listOfProjects.map((progetto, index) => {
                  return (
                    <div className='col-12 py-3'>
                      <CardListaProgetti progetto={progetto} key={index} />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progetti;
