import React, { useContext } from "react";
import { AiOutlineClose as CloseButton } from "react-icons/ai";
import ImmagineTest from "../../img/pattern_login.jpg";
import { Link, useParams } from "react-router-dom";
import ImgCaricamentoContenuti from "../../img/CaricamentoContenuti.jpeg";
import ImgConfiguraProgetto from "../../img/ConfiguraProgetto.jpeg";
import ImgAspettoChat from "../../img/AspettoChat.jpg";
import ImgEmbedSito from "../../img/EmbedSito.jpeg";
import imgArchivio from "../../img/archivio.jpg";
import MenuCaricamentoConteuti from "./MenuCaricamentoConteuti";
import { AiOutlineArrowLeft as BackArrow } from "react-icons/ai";
import { StateContext } from "../StateContainer";
import Cookies from "js-cookie";

const OffcavasGestioneProgetto = ({ progetto }) => {
  const { id } = useParams();
  console.log(id);
  // Funzioni

  const { setSectionMobile, sectionMobile } = useContext(StateContext);

  const CaricamentoContenuti = () => {
    const menuGestioneProgetto = document.getElementById("gestione-progetto");
    const caricamentoContenuti = document.getElementById(
      "menuCaricamentoContenuti"
    );
    caricamentoContenuti.style.display = "block";
    setTimeout(() => {
      menuGestioneProgetto.style.left = "-2000px";
      caricamentoContenuti.style.left = "0";
      setTimeout(() => {
        menuGestioneProgetto.style.display = "none";
      }, 100);
    }, 100);
  };

  const CambiaSezioneMobile = (section) => {
    Cookies.remove("lastSectionMobile");
    setSectionMobile(section);
    Cookies.set("lastSectionMobile", section);
  };

  // ("#dettagliProgetto");
  // Menù Gestione Progetto
  const gestioneProgetti = [
    {
      "id": 1,
      "title": "Comportamento Assistente",
      "img": ImgCaricamentoContenuti,
      "clicked": () => CambiaSezioneMobile("#comportamentoAssistente"),
      "linkTo": `/progetti/modifica-progetto/${progetto.code || id}`,
      "dismiss": true,
    },
    {
      "id": 2,
      "title": "Aspetto Chat",
      "img": ImgAspettoChat,
      "clicked": () => CambiaSezioneMobile("#aspettoChat"),
      "linkTo": `/progetti/modifica-progetto/${progetto.code || id}`,
      "dismiss": true,
    },
    {
      "id": 3,
      "title": "Caricamento Contenuti",
      "img": ImgConfiguraProgetto,
      "clicked": CaricamentoContenuti,
      "linkTo": false,
      "navigateTo": false,
      "dismiss": false,
    },
    {
      "id": 4,
      "title": "Embed sul Sito",
      "img": ImgEmbedSito,
      "clicked": () => CambiaSezioneMobile("#embedProgetto"),
      "linkTo": `/progetti/modifica-progetto/${progetto.code || id}`,
      "dismiss": true,
    },
    {
      "id": 5,
      "title": "Archivio Conversazioni",
      "img": imgArchivio,
      "clicked": () => CambiaSezioneMobile("#archivioConversazioni"),
      "linkTo": `/progetti/modifica-progetto/${progetto.code || id}`,
      "dismiss": true,
    },
  ];

  return (
    <div
      className='offcanvas offcanvas-start w-100 '
      tabIndex='-1'
      id='offcanvasWithBackdrop'
      aria-labelledby='offcanvasWithBackdropLabel'
    >
      <div className='offcanvas-header d-flex justify-content-end my-3 border-0'></div>
      <div className='offcanvas-body border-0 position-relative'>
        <div className='row justify-content-center' id='gestione-progetto'>
          <div className='col-12 d-flex justify-content-between position-relative align-items-center px-4'>
            <Link
              className='btn text-black fs-5 p-0 text-decoration-none'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              to='/progetti'
            >
              <BackArrow /> Torna ai Progetti
            </Link>

            <Link
              className='btn bg-orange text-white rounded-4 my-3 text-decoration-none text-uppercase'
              to={`/progetti/modifica-progetto/${
                progetto.code || id
              }/test-assistente`}
              data-bs-dismiss='offcanvas'
            >
              Prova
            </Link>
          </div>
          <div className='col-12 mt-3 mb-1 text-center'>
            <p className='m-0 text-uppercase fw-bold font-size-20'>
              Gestione Contenuti
            </p>
          </div>
          {gestioneProgetti.map((linkGestione) => {
            return (
              <div
                className='col-12 d-flex justify-content-center position-relative card-gestione-progetto'
                key={linkGestione.id}
              >
                <div
                  className='card mt-3 overflow-hidden position-relative'
                  style={{ width: "90%" }}
                >
                  <div
                    className='card-body'
                    style={{
                      background: `url(${linkGestione.img}) center center / cover no-repeat`,
                      height: "130px",
                    }}
                  ></div>
                  <div className='card-footer text-center bg-orange text-white border-0 h-auto'>
                    <p className='fs-5 m-0 text-uppercase py-3'>
                      {linkGestione.title}
                    </p>
                  </div>
                  <Link
                    className='text-white text-decoration-none text-uppercase stretched-link'
                    onClick={linkGestione.clicked}
                    data-bs-dismiss={linkGestione.dismiss ? "offcanvas" : ""}
                    to={linkGestione.linkTo ? linkGestione.linkTo : null}
                  ></Link>
                </div>
              </div>
            );
          })}
        </div>

        <MenuCaricamentoConteuti
          progetto={progetto}
          id={id}
          CambiaSezioneMobile={CambiaSezioneMobile}
        ></MenuCaricamentoConteuti>
      </div>
    </div>
  );
};

export default OffcavasGestioneProgetto;
