import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { SiGoogle, SiFacebook, SiTwitter } from "react-icons/si";
import ImgLogo from "./img/secondo-logo.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import useAuth from "./UseAuth";
import { StateContext } from "./components/StateContainer";
import PatternLogin from "./img/pattern_login_unico.jpg";
import Esagono from "./img/esagono.svg";
import LogoFulgeas from "./img/Fulgeas_Yellow_White.png";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  googleLogout,
} from "@react-oauth/google";

function Login() {
  const { loading, setLoading, error, setError, setPageTitle } =
    useContext(StateContext);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  useEffect(() => {
    setPageTitle("Login");
  }, []);

  const handleSubmit = (event) => {
    setIsError(false);
    event.preventDefault();
    setLoading(true);
    const loginPayload = { email, password };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login_check`, loginPayload)
      .then(
        (response) => {
          setLoading(false);
          console.log(response.response);
          setError("");
          //get token from response
          console.log(response);
          const token = response.data.token;
          // call the login function from the hook
          login(token);
        },
        (reason) => {
          setLoading(false);
          setIsError(true);
          console.error(reason);
          setError("Email o password errati");
        }
      );
  };

  const GoogleLoginCredentials = (credential) => {
    const loginPayload = { "googleToken": credential };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login_google`, loginPayload)
      .then(
        (response) => {
          setLoading(false);
          console.log(response.response);
          setError("");
          //get token from response
          console.log(response);
          const token = response.data.token;
          login(token);
        },
        (reason) => {
          setLoading(false);
          setIsError(true);
          console.error(reason);
          setError("Email o password errati");
        }
      );
  };

  return (
    <div
      className='login-bg'
      style={{
        background: `url(${PatternLogin}) center center / contain repeat`,
        backgroundSize: "17%",
      }}
    >
      <div className='account-pages pt-sm-5 d-flex align-items-center'>
        <div className='container'>
          <div className='row py-auto mb-2'>
            <div className='col-lg-12 d-flex justify-content-center align-items-center'></div>
          </div>
          <div className='row justify-content-center'>
            <div
              className='col-12 d-flex justify-content-center esagono-img'
              style={{ zIndex: 9999 }}
            >
              <img src={Esagono} alt='' className='svg-shadow' />
            </div>
            <div className='col-12  justify-content-center'>
              <div
                className='text-center align-items-center tailor_logo'
                style={{ zIndex: 99999 }}
              >
                <a className='d-block auth-logo my-auto'>
                  <img src={ImgLogo} alt='' className='logo logo-dark' />
                </a>
              </div>
            </div>
            <div className='col-md-8 col-lg-6 col-xl-5' style={{ zIndex: 99 }}>
              <div className='card position-relative border-0 shadow overflow-hidden card-login p-1'>
                <div className='circle-login' style={{ zIndex: 0 }}></div>
                <div
                  className='card-body d-flex align-items-center justify-content-around position-relative w-100 '
                  style={{ zIndex: 9999 }}
                >
                  <div className='p-2 mt-5 form-body-login w-100'>
                    <form
                      onSubmit={handleSubmit}
                      className='align-items-center'
                    >
                      <div className='mb-3'>
                        <label
                          className='form-label fw-bold'
                          htmlFor='username'
                        >
                          Username
                        </label>
                        <input
                          type='text'
                          className='form-control border-green-second'
                          id='username'
                          placeholder='Inserisci lo Username'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className='mb-3'>
                        <label
                          className='form-label fw-bold'
                          htmlFor='userpassword'
                        >
                          Password
                        </label>
                        <input
                          type='password'
                          className='form-control border-green-second'
                          id='userpassword'
                          placeholder='Inserisci la Password'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div className='mt-3 text-center'>
                        <button
                          className='btn bg-green-second text-white w-sm waves-effect waves-light w-50 mt-2 login-submit'
                          type='submit'
                        >
                          LOGIN
                        </button>
                      </div>
                      <div className='d-flex align-items-center'>
                        <hr className='w-100' />
                        <p className='m-0 p-4'>Oppure</p>
                        <hr className='w-100' />
                      </div>
                      <div className='w-100 d-flex align-items-center justify-content-center'>
                        <GoogleOAuthProvider clientId='647864559124-67la2hh8scvgfd33ghghb4i6uj4dvumf.apps.googleusercontent.com'>
                          <GoogleLogin
                            onSuccess={(credentialResponse) => {
                              GoogleLoginCredentials(
                                credentialResponse.credential
                              );
                            }}
                            onError={() => {
                              console.log("Login Failed");
                            }}
                          />
                        </GoogleOAuthProvider>
                      </div>
                    </form>
                  </div>
                </div>

                <div
                  className='card-footer align-items-center d-flex border-0 bg-blu-fulgeas justify-content-center'
                  style={{ height: "120px" }}
                >
                  <div className='row fulgeas_creator_login justify-content-center w-100'>
                    <div className='col-12 d-flex col-lg-auto justify-content-center pt-2'>
                      <h6 className='text-white fw-bold'>Proudly created by</h6>
                    </div>
                    <div className='col-12 col-lg-auto d-flex justify-content-center pb-2'>
                      <img src={`${LogoFulgeas}`} alt='' />
                    </div>
                  </div>
                </div>
              </div>
              {isError ? (
                <div className='text-danger text-center alert bg-white rounded-3 mt-2 error-credential'>
                  <p className='m-0'>Nome utente e/o password errati</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
