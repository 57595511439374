import React, { useState, useContext, useEffect } from "react";
import { AiOutlinePlusCircle as Aggiungi } from "react-icons/ai";
import { StateContext } from "../StateContainer";
import axios from "axios";
import { MdCancel as Annulla } from "react-icons/md";

const FiltraLeads = ({
  showFilterFormLeads,
  setShowFilterFormLeads,
  setFilterByProjectCode,
  listOfProjects,
  setListOfProjects,
  filterByProjectCode,
  leadTable,
}) => {
  const { token, setLoading, setError } = useContext(StateContext);

  useEffect(() => {
    setLoading(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/list`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setLoading(false);
        setError("");
        setListOfProjects(response.data.response.projects);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          //nothing
        }
      });
  }, []);

  const FiltraPerProgetto = () => {
    setShowFilterFormLeads(!showFilterFormLeads);
    setFilterByProjectCode("");
  };

  const AnnullaFiltraLeads = () => {
    setShowFilterFormLeads(false);
    setFilterByProjectCode("");
  };

  return (
    <div className='row mb-3'>
      {/* Bottone Filtro Elementi */}
      <div className='col-12 px-0'>
        <button
          className={`btn p-0 d-flex align-items-center gap-1 font-size-16 text-muted ${
            showFilterFormLeads ? "ruota-icona" : "no-ruota-icona"
          }`}
          onClick={FiltraPerProgetto}
        >
          <Aggiungi />
          Filtra per Progetto
        </button>
      </div>

      {/* Container Filtro Elementi */}
      <div
        className={`col-12 bg-muted pt-3 d-flex align-items-center gap-2 px-0 ${
          showFilterFormLeads ? "" : "d-none"
        }`}
      >
        <button
          className='btn p-0'
          onClick={AnnullaFiltraLeads}
          style={{ fontSize: "26px" }}
        >
          <Annulla />
        </button>
        <select
          className='form-select selectProject'
          aria-label='Select Project'
          value={filterByProjectCode}
          onChange={(event) => setFilterByProjectCode(event.target.value)}
        >
          <option value={""} name='default'>
            Tutti
          </option>
          {listOfProjects.map((project, index) => {
            return (
              <option name={project.code} value={project.code} key={index}>
                {project.title}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default FiltraLeads;
