import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../StateContainer";
import axios from "axios";
import Moment from "react-moment";
import {
  LineChart,
  XAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  Line,
  Area,
  YAxis,
  ResponsiveContainer,
  AreaChart,
} from "recharts";

const Analytics = () => {
  const { token } = useContext(StateContext);
  const [analytics, setAnalytics] = useState([]);

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/analytics/interaction-per-day`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          console.log(response.data.response);
          const newData = [];
          Object.keys(response.data.response).forEach((elemento) => {
            let value = response.data.response[elemento];

            newData.push({
              day: value.day,
              count: value.count,
            });
          });

          setAnalytics(newData);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const TooltipContent = (props) => {
    if (!props.active || !props.payload) {
      return;
    }
    const data = props.payload[0].payload;
    return (
      <div
        style={{
          border: "1px black solid",
          padding: "10px",
          borderRadius: "5px",
          background: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <div>
          Giorno: <Moment format='DD MMMM YYYY'>{data.day}</Moment>
        </div>
        <div>Interazioni: {data.count}</div>
      </div>
    );
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={20}
          textAnchor='end'
          fill='#666'
          transform='rotate(-35)'
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div className='card'>
      <div className='card-header bg-white border-0 pb-4 pt-3 h5'>
        Numero di Interazioni
      </div>
      <div className='card-body'>
        <ResponsiveContainer width='100%' height={400}>
          <AreaChart
            width={"100%"}
            data={analytics}
            margin={{ right: 20, left: 10, bottom: 80 }}
          >
            <CartesianGrid></CartesianGrid>
            <XAxis dataKey='day' tick={<CustomizedAxisTick />} />
            <YAxis dataKey='count' type='number' />

            <Area
              name={"Numero di Interazioni"}
              dataKey={"count"}
              fill='#fc9129'
              strokeWidth={0}
              type='monotone'
            />
            <Tooltip content={<TooltipContent></TooltipContent>}></Tooltip>
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Analytics;
