import React, { useContext, useState } from "react";
import { StateContext } from "../../StateContainer";
import { AiFillInfoCircle as Info } from "react-icons/ai";
import { MdArrowDropUp as Arrow } from "react-icons/md";

const BasicInformationChat = ({ isPanelActive, handlePanelToggle }) => {
  const {
    setPrimoMessaggioBot,
    setAllineamentoBottoneChatbot,
    setAssistantName,
    setPrivacyLink,
    primoMessaggioBot,
    allineamentoBottoneChatbot,
    assistantName,
    privacyLink,
    windowWidth,
  } = useContext(StateContext);

  const [showInformazioniBase, setShowInformazioniBase] = useState(false);
  return (
    <>
      <div className='w-100 position-relative collapse-menu'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <Info></Info>
            <h6 className='m-0 fw-bold'>Informazioni Base</h6>
          </div>

          <div>
            <Arrow
              className={`fs-3 ${
                isPanelActive(1) ? "down-arrow" : "top-arrow"
              }`}
            ></Arrow>
          </div>
        </div>
        <a
          className='stretched-link pe-auto'
          type='button'
          data-bs-toggle={`collapse`}
          data-bs-target='#basicInformationChat'
          aria-expanded='false'
          aria-controls='basicInformationChat'
          onClick={() => handlePanelToggle(1)}
        ></a>
      </div>

      <div
        className={`collapse`}
        id='basicInformationChat'
        data-bs-parent='#accordionAspettoChat'
      >
        <div className='col-12 mb-3 mt-2'>
          <label className='form-label' htmlFor='firstPrompt'>
            Messaggio di Benvenuto
          </label>

          <textarea
            type='text'
            placeholder="Scrivi il primo messaggio del bot all'utente"
            id='firstPrompt'
            value={primoMessaggioBot || ""}
            onChange={(event) => setPrimoMessaggioBot(event.target.value)}
            className='form-control form-control-lg fs-6'
            style={{ maxHeight: "200px", overflowY: "hidden" }}
          />
          <p className='text-muted my-2'>
            Per visualizzare il messaggio in più baloon, manda a capo
          </p>
        </div>
        <div className='col-12 py-3'>
          <label htmlFor='selezionaAllineamentoBottone'>
            Posizione bottone dell'Assistente
          </label>
          <select
            className='form-select w-100 mt-2'
            aria-label='Default select example'
            id='selezionaAllineamentoBottone'
            value={allineamentoBottoneChatbot || "dx"}
            onChange={(event) =>
              setAllineamentoBottoneChatbot(event.target.value)
            }
          >
            <option value='sx'>Sinistra</option>
            <option value='dx'>Destra</option>
          </select>
        </div>
        <div className='col-12 mb-1 py-3'>
          <label className='form-label' htmlFor='emailNotifyTo'>
            Il nome dell'Assistente:
          </label>
          <input
            type='text'
            name='text'
            autoComplete='on'
            placeholder='Inserisci il Nome del tuo assistente'
            className='form-control form-control-lg fs-6'
            value={assistantName || ""}
            onChange={(event) => setAssistantName(event.target.value)}
            required
          />
          <p className='text-muted my-2'>Il nome del tuo Assistente</p>
        </div>
        <div className='col-12 mb-3 pt-2'>
          <label className='form-label' htmlFor='privacyLink'>
            Link alla pagina privacy
          </label>
          <input
            type='text'
            name='text'
            autoComplete='on'
            placeholder='Esempio: https://www.sitoesempio.com/privacy'
            className='form-control form-control-lg fs-6'
            value={privacyLink || ""}
            onChange={(event) => setPrivacyLink(event.target.value)}
            required
          />
        </div>
      </div>
    </>
  );
};

export default BasicInformationChat;
