import React, { useEffect, useContext, useState } from "react";
import { StateContext } from "../StateContainer";
import axios from "axios";
import { IoCheckmarkDoneCircleSharp as IconaFatto } from "react-icons/io5";
import { Link } from "react-router-dom";
import useAuth from "../../UseAuth";

const ModalLogout = () => {
  const { logout } = useAuth();
  const { error, setError, token, nuovoProgetto, setNuovoProgetto } =
    useContext(StateContext);

  const [titoloProgetto, setTitoloProgetto] = useState("");
  const [loadingProject, setLoadingProject] = useState(false);

  const closeModalCreateButton = document.getElementById("close-modal-create");

  const inputCrea = document.getElementById("formControlLgcvv");

  const CancellaCronologiaProgetto = () => {
    setNuovoProgetto([]);
    setLoadingProject(false);
    setTitoloProgetto("");
  };

  let modal = document.getElementById("modalLogout");
  let body = document.getElementById("bodyApp");

  const RemoveOverlay = () => {
    modal.classList.add("d-none");
    body.classList.remove("modal-open");
    body.classList.add("overflow-auto");
    CancellaCronologiaProgetto();
  };

  return (
    <div
      className='modal fade overlay'
      id='modalLogout'
      tabIndex='-1'
      aria-labelledby='modalLogoutLabel'
      aria-hidden='true'
      data-bs-backdrop='false'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header border-0 py-4 d-flex align-items-center justify-content-between'>
            <h4 className='m-0'>Logout</h4>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              id='close-modal-create'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <p>
              Stai effettuando la Logout da TailorGPT. Vuoi procedere comunque?
            </p>
          </div>
          <div className='modal-footer border-0'>
            <>
              <button
                type='button'
                className={`btn text-white bg-green-second ${
                  loadingProject ? "disabled" : ""
                }`}
                data-bs-dismiss='modal'
                onClick={CancellaCronologiaProgetto}
              >
                Annulla
              </button>
              <button
                type='button'
                className={`btn bg-danger text-white ${
                  loadingProject ? "disabled" : ""
                }`}
                id='creaProgettoButton'
                onClick={() => logout()}
              >
                Esci
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLogout;
