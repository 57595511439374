import React, { useState, useContext, useEffect } from "react";
import Moment from "react-moment";
import "moment/locale/it";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { IoInformationSharp as Information } from "react-icons/io5";
import { ImParagraphLeft as Visualizza } from "react-icons/im";
import Info from "./Modals/Info";

const TestiLiberiCaricatiMobile = ({
  freetext,
  index,
  EditFreeText,
  DeleteFreeText,
}) => {
  return (
    <>
      <div
        className='row d-flex align-items-center py-3'
        style={{ borderBottom: "1px black solid" }}
        key={index}
      >
        <div className='col-1 fw-bold'>{index + 1}</div>
        <div className='col-auto font-size-14 '>
          {freetext.title.length < 50
            ? freetext.title
            : freetext.title.substr(0, 46) + " ..."}
        </div>

        {/* Informazioni url del sito */}
        <div className='col w-auto d-flex justify-content-end gap-2'>
          <button
            className='btn btn-success text-white p-0 px-2 fs-5 d-flex align-items-center'
            data-bs-toggle='modal'
            data-bs-target={`#info-${index}`}
          >
            <Information></Information>
          </button>

          {/* Elimina url del sito */}
          <button
            className='btn btn-danger text-white px-2 fs-5 d-flex align-items-center'
            title='Elimina Domanda e risposta'
            onClick={() => DeleteFreeText(freetext.code)}
          >
            <Cestino></Cestino>
          </button>
          <button
            className='btn btn-primary text-white p-0 px-2 fs-5 d-flex align-items-center'
            title='Aggiorna Domanda e risposta'
            href={`#aggiornaFaq-${index}`}
            onClick={() =>
              EditFreeText(freetext.code, freetext.title, freetext.text)
            }
          >
            <Matita></Matita>
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Info
            index={index}
            primoTitolo={"Titolo Testo Libero"}
            secondoTitolo={"Data di Caricamento"}
            first_element={freetext.title}
            second_element={freetext.updated.date}
            third_element={freetext.charsCount}
          ></Info>
        </div>
      </div>
    </>
  );
};

export default TestiLiberiCaricatiMobile;
