import React, { useContext, useEffect } from "react";
import { StateContext } from "../StateContainer";

const LeadContainer = ({ progetto }) => {
  const {
    setCollectLeadName,
    setCollectLeadEmail,
    setCollectLeadCompany,
    setCollectLeadJobTitle,
    setCollectLeadPhone,
    setCollectLeadMessage,
    setCollectLeadWhen,
    setAllowSkipContact,
    leads,
  } = useContext(StateContext);

  useEffect(() => {
    if (progetto[0]) {
      setCollectLeadName(progetto[0].lead.collectLeadName);
      setCollectLeadPhone(progetto[0].lead.collectLeadPhone);
      setCollectLeadEmail(progetto[0].lead.collectLeadEmail);
      setCollectLeadMessage(progetto[0].lead.collectLeadMessage);

      setCollectLeadWhen(progetto[0].lead.collectLeadWhen);
      setCollectLeadJobTitle(progetto[0].lead.collectLeadJobTitle);
      setCollectLeadCompany(progetto[0].lead.collectLeadCompany);
      setAllowSkipContact(progetto[0].lead.allowSkipContact);
    }
  }, [progetto]);

  return (
    <>
      {leads.map((lead) => {
        return lead.type === "select" ? (
          // Tipo Select Input
          <div className='col-12  mb-5' key={lead.id}>
            <label htmlFor={lead.name}>{lead.textLabel}</label>
            <select
              className={`form-select mt-3 `}
              value={lead.value}
              onChange={lead.functionChange}
            >
              {lead.options.map((option) => {
                return (
                  <option key={option.id} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
            <p className='mt-2 text-muted'>{lead.spiegazione}</p>
          </div>
        ) : lead.type === "text" ? (
          // Tipo Testo Input
          <div className='col-12  mb-5' key={lead.id}>
            <label htmlFor={lead.name}>{lead.textLabel}</label>
            <div className='input-group '>
              <input
                type={lead.type}
                role={lead.type}
                value={lead.value}
                className='form-control w-100 mt-3'
                placeholder={lead.placeholder}
                id={lead.name}
                onChange={lead.functionChange}
              />
            </div>
            <p className='mt-2 text-muted'>{lead.spiegazione}</p>
          </div>
        ) : (
          // Tipo Switch Input
          <div className='col-12  mb-5' key={lead.id}>
            <label
              htmlFor={lead.name}
              className='d-flex align-items-center justify-content-between'
            >
              <p>{lead.textLabel}</p>
              <div className={`form-${lead.role} d-flex align-items-center `}>
                <input
                  id={lead.name}
                  type={lead.type}
                  role={lead.role}
                  value={lead.value}
                  onChange={lead.functionChange}
                  className='form-check-input bg-muted'
                  checked={lead.value ? "checked" : ""}
                />
              </div>
            </label>
            <p className='mt-2 text-muted'>{lead.spiegazione}</p>
          </div>
        );
      })}
    </>
  );
};

export default LeadContainer;
