import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../StateContainer";
import { AiOutlineClose as Close } from "react-icons/ai";

const PredefinedQueries = ({ progetto }) => {
  const { queries, setQueries } = useContext(StateContext);

  // Funzione per aggiungere una coppia di input
  const addQuery = () => {
    const newQuery = { label: "", question: "" };
    setQueries([...queries, newQuery]);
  };

  // Funzione per rimuovere una coppia di input
  const removeQuery = (index) => {
    const updatedQueries = [...queries];
    updatedQueries.splice(index, 1);
    setQueries(updatedQueries);
  };

  // Funzione per gestire il cambiamento del valore "Label"
  const handleLabelChange = (index, event) => {
    const updatedQueries = [...queries];
    updatedQueries[index].label = event.target.value;
    setQueries(updatedQueries);
  };

  // Funzione per gestire il cambiamento del valore "Question"
  const handleQuestionChange = (index, event) => {
    const updatedQueries = [...queries];
    updatedQueries[index].question = event.target.value;
    setQueries(updatedQueries);
  };

  return (
    <div className='px-3'>
      <div>
        <p className='text-muted mb-3'>
          Vengono creati dei bottoni con dei suggerimenti di interazione con
          l'assistente. Compila l'etichetta del bottone e la relativa domanda
          preconfigurata.
        </p>
      </div>
      <button
        className='btn bg-green-second text-white mb-3 '
        onClick={addQuery}
        name='add-predefined'
      >
        + Aggiungi Domanda
      </button>
      <div id='containerPredefinedQueries' className='row'>
        {queries.map((query, index) => (
          <div key={index} className='mb-2 row my-2'>
            <div className='d-grid col'>
              <label htmlFor='label' className='mb-1'>
                Etichetta
              </label>
              <input
                type='text'
                placeholder='Label'
                className='form-control'
                name='label'
                value={query.label}
                onChange={(event) => handleLabelChange(index, event)}
              />
            </div>
            <div className='d-grid col'>
              <label htmlFor='label' className='mb-1'>
                Domanda
              </label>
              <input
                type='text'
                placeholder='Domanda'
                className='form-control'
                value={query.question}
                onChange={(event) => handleQuestionChange(index, event)}
              />
            </div>
            <div className='col-auto d-flex align-items-end '>
              <button
                className='btn close-predefined-queries-button'
                onClick={() => removeQuery(index)}
              >
                <Close />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PredefinedQueries;
