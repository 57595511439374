import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../StateContainer";
import { BsFillArrowRightCircleFill as FrecciaDestra } from "react-icons/bs";

function Items({ currentItems }) {
  const navigate = useNavigate();
  const { sectionMobile, setSectionMobile, setConversationCode } =
    useContext(StateContext);

  const VaiAlloStorico = (item) => {
    let conversationCode = item.conversation ? item.conversation.code : "";
    setConversationCode(conversationCode);
    navigate(
      `/progetti/modifica-progetto/${item.project.code}/test-assistente`
    );
  };
  return (
    <table className='table d-none d-lg-table'>
      <thead>
        <tr>
          <th scope='col'>Nome</th>
          <th scope='col'>Email</th>
          <th scope='col'>Professione</th>
          <th scope='col'>Azienda</th>
          <th scope='col'>Telefono</th>
          <th scope='col'>Link</th>
          <th scope='col'>Data</th>
          <th scope='col'>Conversazione</th>
        </tr>
      </thead>
      <tbody className='tableElencoLead bg-white'>
        {currentItems &&
          currentItems.map((item, index) => (
            <tr key={index} className='tr-tableElencoLead'>
              <td className='py-3 align-middle'>{item.name || "-"}</td>
              <td className='py-3 align-middle'>{item.email || "-"}</td>
              <td className='py-3 align-middle'>{item.jobTitle || "-"}</td>
              <td className='py-3 align-middle'>{item.company || "-"}</td>
              <td className='py-3 align-middle'>{item.phone || "-"}</td>
              <td className='py-3 align-middle'>
                {(item.conversation && item.conversation.link) || "-"}
              </td>
              <td className='py-3 align-middle'>
                <Moment format='DD/MM/YYYY HH:mm'>{item.created.date}</Moment>
              </td>
              <td
                className={`py-3 align-items-center justify-content-between align-middle`}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  {item.conversation ? item.conversation.lastUserMessage : "-"}

                  {item.conversation && (
                    <button
                      className='btn fs-3 float-end'
                      onClick={() => VaiAlloStorico(item)}
                    >
                      <FrecciaDestra />
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        {currentItems.length === 0 && (
          <tr className='text-center'>
            <td colSpan={7} className='py-3'>
              Nessun Lead è stato trovato per il progetto selezionato
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default function PaginationLeads({ itemsPerPage, items }) {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <div className='navigation d-none d-lg-block'>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel='...'
        nextLabel='>>'
        onPageChange={handlePageClick}
        pageClassName='position-relative'
        previousClassName='position-relative'
        nextClassName='position-relative'
        pageLinkClassName='stretched-link'
        previousLinkClassName='stretched-link'
        nextLinkClassName='stretched-link'
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel='<<'
        renderOnZeroPageCount={null}
        activeLinkClassName='text-white'
      />
    </div>
  );
}
