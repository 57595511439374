import React, { useContext } from "react";
import { StateContext } from "../StateContainer";
import StoricoConversazioni from "../StoricoConversazioni";

const MenuConversazioniPrecedenti = () => {
  const { setMenuConversazioniPrecedenti, menuConversazioniPrecedenti } =
    useContext(StateContext);
  return (
    <>
      <div
        className='offcanvas offcanvas-start'
        data-bs-scroll='true'
        //   data-bs-backdrop="false"
        tabIndex='-1'
        id='MenuConversazioniPrecedenti'
        aria-labelledby='MenuConversazioniPrecedentiLabel'
      >
        <StoricoConversazioni></StoricoConversazioni>
      </div>
    </>
  );
};

export default MenuConversazioniPrecedenti;
