import React, { useContext, useEffect } from "react";
import ChatbotConStorico from "../../ChatbotConStorico";
import { StateContext } from "../../StateContainer";
import axios from "axios";
import { useParams } from "react-router-dom";
import { RxHamburgerMenu as Hamburger } from "react-icons/rx";
import MenuConversazioniPrecedenti from "../../ArchivioConversazioniPrecedenti/MenuConversazioniPrecedenti";

const ArchivioConversazioni = ({ progetto, idProgetto }) => {
  const {
    dettagliProgetto,
    setMenuConversazioniPrecedenti,
    menuConversazioniPrecedenti,
    conversationCode,
    titoloProgetto,
    aggiornamentoDatiProgetto,
    allProjectInformation,
    setAllProjectInformation,
    setLoading,
    setError,
    token,
    setConversationCode,
  } = useContext(StateContext);

  console.log("dettagli", dettagliProgetto);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: idProgetto,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setAllProjectInformation(response.data.response);
          console.log(response.data.response);
        }
      })
      .catch((err) => setError(err));
  }, [aggiornamentoDatiProgetto]);

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/conversation/list`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: idProgetto,
        limit: 20,
        offset: "",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          if (
            window.location.pathname ===
            `/progetti/modifica-progetto/${idProgetto}`
          ) {
            setConversationCode(response.data.response[0].code);
          }
        }
      })
      .catch((err) => setError(err));
  }, []);

  console.log("all1", allProjectInformation);

  let iframeCode = `${process.env.REACT_APP_CHATBOT}?projectCode=${idProgetto}`;

  return (
    <div className='iframe-container position-relative custom-iframe-container'>
      <div className='w-100 bg-white py-3 px-2 text-end fs-5 navbar-archivio'>
        <button
          className='btn bg-green-second text-white'
          onClick={() =>
            setMenuConversazioniPrecedenti(!menuConversazioniPrecedenti)
          }
          type='button'
          data-bs-toggle='offcanvas'
          data-bs-target='#MenuConversazioniPrecedenti'
          aria-controls='MenuConversazioniPrecedenti'
          alt='Storico Conversazioni'
        >
          Storico Conversazioni
        </button>

        <MenuConversazioniPrecedenti></MenuConversazioniPrecedenti>
      </div>
      {conversationCode === "" ? (
        <iframe src={iframeCode} id='iframe-chatbot'></iframe>
      ) : (
        <ChatbotConStorico
          conversationCode={conversationCode}
          title={titoloProgetto}
          allProjectInformation={allProjectInformation}
        />
      )}
    </div>
  );
};

export default ArchivioConversazioni;
