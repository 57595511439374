import React, { useContext, useEffect, useState } from "react";
import ZipPlugin from "../../assets/plugins/wp-tailorgpt.zip";
import { MdFolder as Cartella } from "react-icons/md";
import { StateContext } from "../StateContainer";
import { useParams } from "react-router-dom";

const EmbedSitoMobile = () => {
  const { id } = useParams();

  const { setAggiornamentoDatiProgetto, setPageTitle } =
    useContext(StateContext);

  let iframeCode = `${process.env.REACT_APP_CHATBOT}?projectCode=${id}`;

  useEffect(() => {
    setPageTitle("Includi nel progetto");
  }, []);

  const [dettagliProgetto, setDettagliProgetto] = useState([]);

  const [copiatoCodiceIframe, setCopiatoCodiceIframe] = useState(false);
  const [copiatoCodiceScript, setCopiatoCodiceScript] = useState(false);

  const handleCopyClick = () => {
    let iframeCode = document.getElementById("iframeCode").innerText;
    setCopiatoCodiceIframe(false);
    setCopiatoCodiceScript(false);

    navigator.clipboard
      .writeText(iframeCode)
      .then(function () {
        setCopiatoCodiceIframe(true);
      })
      .catch(function (error) {
        console.error("Errore durante la copia del codice:", error);
      });
  };

  const handleCopyClickScript = () => {
    setCopiatoCodiceIframe(false);
    setCopiatoCodiceScript(false);
    let scriptCode = document.getElementById("scriptCode").innerText;

    navigator.clipboard
      .writeText(scriptCode)
      .then(function () {
        setCopiatoCodiceScript(true);
      })
      .catch(function (error) {
        console.error("Errore durante la copia del codice:", error);
      });
  };

  const RemoveUpdateDataModal = () => {
    setAggiornamentoDatiProgetto("");
  };

  const RemoveErrorUpdateDataModal = () => {
    setAggiornamentoDatiProgetto("");
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {copiatoCodiceIframe ? (
          <div className='alert alert-success mt-3' role='alert'>
            Codice <code>&lt;iframe/&gt;</code> copiato negli appunti
          </div>
        ) : (
          ""
        )}
        <div className='card p-2 mt-4'>
          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
            <h6 className='m-0 fw-bold'>Copia il codice dell'iframe</h6>
            <button
              id='copyButton'
              className='btn btn-dark'
              onClick={handleCopyClick}
            >
              Copia
            </button>
          </div>
          <div className='card-body'>
            <code
              id='iframeCode'
              onClick={handleCopyClick}
              style={{ cursor: "pointer" }}
            >
              &lt;iframe src="{iframeCode}" width="100%"
              height="400px"&gt;&lt;/iframe&gt;
            </code>
          </div>
        </div>
      </div>
      {/* Script */}
      <div className='col-12'>
        {copiatoCodiceScript ? (
          <div className='alert alert-success mt-3' role='alert'>
            Codice <code>&lt;script/&gt;</code> copiato negli appunti
          </div>
        ) : (
          ""
        )}
        <div className='card p-2 mt-4'>
          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
            <h6 className='m-0 fw-bold'>Copia il codice dello script</h6>
            <button
              id='copyButtonScript'
              className='btn btn-dark'
              onClick={handleCopyClickScript}
            >
              Copia
            </button>
          </div>
          <div className='card-body'>
            <code
              id='scriptCode'
              onClick={handleCopyClickScript}
              style={{ cursor: "pointer" }}
            >
              &lt;script src="https://app.tailorgpt.ai/js/ScriptChatbot.js"
              projectCode="{`${id}`}
              "&gt;&lt;/script&gt;
            </code>
          </div>
        </div>

        <div className='card p-2 mt-4'>
          <div className='card-header bg-white d-flex align-items-center justify-content-between'>
            <h6 className='m-0 fw-bold'>
              Scarica il plugin per il tuo sito Wordpress
            </h6>
            <a id='copyButtonScript' className='btn btn-dark' href={ZipPlugin}>
              Download
            </a>
          </div>
          <div className='card-body'>
            <a
              href={ZipPlugin}
              className='text-dark text-decoration-none d-flex align-items-center gap-2'
            >
              <Cartella /> Plugin Wordpress Tailor GPT
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbedSitoMobile;
