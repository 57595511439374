import React, { useContext, useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { Link } from "react-router-dom";
import { StateContext } from "./components/StateContainer";
import axios from "axios";
import RigaProgetto from "./components/RigaProgetto";
import ModalCreaProgetto from "./components/Modals/ModalCreaProgetto";
import ModalError from "./components/Modals/ModalError";
import CardListaProgetti from "./components/MobileComponents/CardListaProgetti";
import ModalSuccess from "./components/Modals/ModalSuccess";
import Analytics from "./components/Analytics/Analytics";

const Homepage = () => {
  const {
    loading,
    setLoading,
    codiceProgetto,
    projectTitle,
    error,
    setError,
    token,
    listOfProjects,
    setListOfProjects,
    setEliminaProgetto,
    setNuovoProgetto,
    nuovoProgetto,
    setPageTitle,
    eliminaProgetto,
    userPlanMaxProjects,
    windowWidth,
    CheckCodiceProgetto,
  } = useContext(StateContext);

  useEffect(() => {
    setPageTitle("Dashboard");
  }, []);

  useEffect(() => {
    setLoading(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/list`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setLoading(false);
        setError("");
        setListOfProjects(response.data.response.projects);
        setLoading(false);
        setError("An error occurred. Please try again.");
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          //nothing
        }
      });
  }, [nuovoProgetto, eliminaProgetto]);

  const CloseDeleteWarning = () => {
    setEliminaProgetto([]);
  };

  const CloseNewProjectAlert = () => {
    setNuovoProgetto([]);
  };
  return (
    <div className='App'>
      <div id='layout-wrapper'>
        <Navbar />
        <Sidebar />
        <div className='main-content'>
          <div className='page-content' id='page-content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='page-title-box d-flex align-items-center justify-content-between'>
                        <h4 className='mb-0'>Dashboard</h4>

                        <div className='page-title-right'>
                          <ol className='breadcrumb m-0'>
                            <li className='breadcrumb-item'>
                              <Link
                                to='/dashboard'
                                className='text-decoration-none text-secondary'
                              >
                                Home
                              </Link>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <div className='col-12 mb-4'>
                      <button
                        type='button'
                        className={`btn bg-green-second text-white ${
                          userPlanMaxProjects <= listOfProjects.length
                            ? "d-none"
                            : ""
                        }`}
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal'
                      >
                        + Aggiungi progetto
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 mb-4'>
                <Analytics></Analytics>
              </div>

              {nuovoProgetto.data ? (
                nuovoProgetto.data.status === true ? (
                  <ModalSuccess
                    title='Progetto creato'
                    message='Il tuo progetto è stato creato correttamente'
                    FunctionRemoveModal={setNuovoProgetto}
                  />
                ) : (
                  <ModalError />
                )
              ) : (
                ""
              )}

              <div className='row'>
                <div className='col-12'>
                  <ModalCreaProgetto></ModalCreaProgetto>
                </div>
                {windowWidth > 992 ? (
                  <div className='col-12'>
                    <div className='card p-4 shadow '>
                      <div className='table-responsive pb-3 '>
                        <table className='table table-centered table-nowrap mb-0 w-100 table_content'>
                          <thead className='thead-progetti-home'>
                            <tr className=''>
                              <th scope='col'>NOME</th>

                              <th scope='col'>CARATTERI TOTALI</th>
                              <th scope='col'>DATA CREAZIONE</th>
                              <th scope='col'>AZIONI</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listOfProjects.length === 0 ? (
                              <>
                                <tr className='text-center border-0'>
                                  <td
                                    colSpan={4}
                                    className='pt-4 pb-2 border-0'
                                  >
                                    Ancora nessun progetto
                                  </td>
                                </tr>
                                <tr className='text-center border-0'>
                                  <td colSpan={4} className='border-0'>
                                    <button
                                      type='button'
                                      className={`btn bg-green-second text-white ${
                                        userPlanMaxProjects <=
                                        listOfProjects.length
                                          ? "d-none"
                                          : ""
                                      }`}
                                      data-bs-toggle='modal'
                                      data-bs-target='#exampleModal'
                                    >
                                      + Crea nuovo progetto
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              listOfProjects.map((progetto, index) => {
                                return (
                                  <RigaProgetto
                                    progetto={progetto}
                                    key={index}
                                  ></RigaProgetto>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : listOfProjects.length === 0 ? (
                  <div className='col-12 text-center py-3 '>
                    <p className='placeholder'>Nessun progetto caricato</p>
                  </div>
                ) : (
                  listOfProjects.map((progetto, index) => {
                    return (
                      <div className='col-12 py-3'>
                        <CardListaProgetti progetto={progetto} key={index} />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
