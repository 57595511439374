import React, { useContext, useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import FaqImg from "./img/faqs-img.png";
import { BsQuestionCircle } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { StateContext } from "./components/StateContainer";
import { Link } from "react-router-dom";

const Supporto = () => {
  const { setPageTitle } = useContext(StateContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setPageTitle("Supporto");
  }, []);

  return (
    <div className='App'>
      <div id='layout-wrapper'>
        <Navbar />
        <Sidebar />
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='page-title-box d-flex align-items-center justify-content-between'>
                    <h4 className='mb-0'>Supporto</h4>

                    <div className='page-title-right'>
                      <ol className='breadcrumb m-0'>
                        <li className='breadcrumb-item text-muted'>
                          <Link
                            to='/dashboard'
                            className='text-decoration-none text-muted'
                          >
                            Home
                          </Link>
                        </li>
                        <li className='breadcrumb-item text-muted test'>Supporto</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row justify-content-center mt-4'>
                        <div className='col-lg-5'>
                          <div className='text-center'>
                            <h5>Hai bisogno di assistenza? Contattaci ora. </h5>
                            <p className='text-muted test'>
                              Se hai bisogno di utileriore assistenza o hai
                              domande sul nostro prodotto, puoi contattare il
                              nostro servizio clienti tramite il servizio mail.
                              Il nostro tema è sempre pronto a rispondere alle
                              tue richieste e fornirti il miglior supporto
                              possibile.
                            </p>

                            <div>
                              <a
                                href='mailto:info@fulgeas.it'
                                type='button'
                                className='btn btn-primary mt-2 me-2 waves-effect waves-light'
                              >
                                Scrivici una mail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mt-5'>
                        <div className='col-xl-3 col-sm-5 mx-auto'>
                          <div>
                            <img
                              src={FaqImg}
                              alt=''
                              className='img-fluid mx-auto d-block'
                            />
                          </div>
                        </div>

                        <div className='col-xl-8'>
                          <div
                            id='faqs-accordion'
                            className='custom-accordion mt-5 mt-xl-0 text-decoration-none'
                          >
                            <div className='card border shadow-none'>
                              <a
                                href='#faqsGeneral'
                                className='text-dark collapsed text-decoration-none'
                                data-bs-toggle='collapse'
                                aria-haspopup='true'
                                aria-expanded='false'
                                aria-controls='faqsGeneral'
                                onClick={handleModalToggle}
                              >
                                <div className='bg-light p-3'>
                                  <div className='d-flex align-items-center'>
                                    <div className='flex-shrink-0 me-3'>
                                      <div className='avatar-xs'>
                                        <div className='avatar-title rounded-circle font-size-22 '>
                                          <BsQuestionCircle className='text-white' />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='flex-grow-1 overflow-hidden'>
                                      <h5 className='font-size-16 mb-1'>
                                        Domande Generali
                                      </h5>
                                      <p className='text-muted text-truncate mb-0'>
                                        Indicazioni sulla Chat
                                      </p>
                                    </div>
                                    <div className='flex-shrink-0'>
                                      {!isModalOpen ? (
                                        <MdOutlineKeyboardArrowDown />
                                      ) : (
                                        <MdOutlineKeyboardArrowUp />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </a>

                              <div
                                id='faqsGeneral'
                                className='collapse'
                                data-bs-parent='#faqs-accordion'
                              >
                                <div className='p-4'>
                                  <div className='row'>
                                    <div className='col-md-6'>
                                      <div>
                                        <div className='d-flex align-items-start mt-4'>
                                          <div className='flex-shrink-0 me-3'>
                                            <div className='avatar-xs'>
                                              <div className='avatar-title rounded-circle bg-primary-subtle text-primary font-size-22 commonQuestions'>
                                                <BsQuestionCircle />
                                              </div>
                                            </div>
                                          </div>

                                          <div className='flex-grow-1'>
                                            <h5 className='font-size-16 mt-1'>
                                              Domande Generali
                                            </h5>
                                            <p className='text-muted'>
                                              Domande sui piani e i prezzi del
                                              servizio
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='card border shadow-none'>
                              <a
                                href='#faqs-privacy-policy-collapse'
                                className='text-dark collapsed text-decoration-none'
                                data-bs-toggle='collapse'
                                aria-haspopup='true'
                                aria-expanded='false'
                                aria-controls='faqs-privacy-policy-collapse'
                                onClick={handleModalToggle}
                              >
                                <div className='bg-light p-3'>
                                  <div className='d-flex align-items-center'>
                                    <div className='flex-shrink-0 me-3'>
                                      <div className='avatar-xs'>
                                        <div className='avatar-title rounded-circle font-size-22 '>
                                          <BsQuestionCircle className='text-white' />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='flex-grow-1 overflow-hidden'>
                                      <h5 className='font-size-16 mb-1'>
                                        Privacy Policy
                                      </h5>
                                      <p className='text-muted text-truncate mb-0'>
                                        Indicazioni sulla Privacy Policy
                                      </p>
                                    </div>
                                    <div className='flex-shrink-0'>
                                      {!isModalOpen ? (
                                        <MdOutlineKeyboardArrowDown />
                                      ) : (
                                        <MdOutlineKeyboardArrowUp />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </a>

                              <div
                                id='faqs-privacy-policy-collapse'
                                className='collapse'
                                data-bs-parent='#faqs-accordion'
                              >
                                <div className='p-4'>
                                  <div className='row'>
                                    <div className='col-md-6'>
                                      <div>
                                        <div className='d-flex align-items-start mt-4'>
                                          <div className='flex-shrink-0 me-3'>
                                            <div className='avatar-xs'>
                                              <div className='avatar-title rounded-circle bg-primary-subtle text-primary font-size-22 commonQuestions'>
                                                <BsQuestionCircle />
                                              </div>
                                            </div>
                                          </div>

                                          <div className='flex-grow-1'>
                                            <h5 className='font-size-16 mt-1'>
                                              Piani e prezzi
                                            </h5>
                                            <p className='text-muted'>
                                              Domande sui piani e i prezzi del
                                              servizio
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='card border shadow-none'>
                              <a
                                href='#pricing'
                                className='text-dark collapsed text-decoration-none'
                                data-bs-toggle='collapse'
                                aria-haspopup='true'
                                aria-expanded='false'
                                aria-controls='pricing'
                                onClick={handleModalToggle}
                              >
                                <div className='bg-light p-3'>
                                  <div className='d-flex align-items-center'>
                                    <div className='flex-shrink-0 me-3'>
                                      <div className='avatar-xs'>
                                        <div className='avatar-title rounded-circle font-size-22 '>
                                          <BsQuestionCircle className='text-white' />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='flex-grow-1 overflow-hidden'>
                                      <h5 className='font-size-16 mb-1'>
                                        Privacy Policy
                                      </h5>
                                      <p className='text-muted text-truncate mb-0'>
                                        Indicazioni sulla Privacy Policy
                                      </p>
                                    </div>
                                    <div className='flex-shrink-0'>
                                      {!isModalOpen ? (
                                        <MdOutlineKeyboardArrowDown />
                                      ) : (
                                        <MdOutlineKeyboardArrowUp />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </a>

                              <div
                                id='pricing'
                                className='collapse'
                                data-bs-parent='#faqs-accordion'
                              >
                                <div className='p-4'>
                                  <div className='row'>
                                    <div className='col-md-6'>
                                      <div>
                                        <div className='d-flex align-items-start mt-4'>
                                          <div className='flex-shrink-0 me-3'>
                                            <div className='avatar-xs'>
                                              <div className='avatar-title rounded-circle bg-primary-subtle text-primary font-size-22 commonQuestions'>
                                                <BsQuestionCircle />
                                              </div>
                                            </div>
                                          </div>

                                          <div className='flex-grow-1'>
                                            <h5 className='font-size-16 mt-1'>
                                              Piani e prezzi
                                            </h5>
                                            <p className='text-muted'>
                                              Domande sui piani e i prezzi del
                                              servizio
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Supporto;
