import React from "react";
import ImgLogo from "./img/Fulgeas_logo.png";
import { Link } from "react-router-dom";
import { SiFacebook, SiGoogle, SiTwitter } from "react-icons/si";

const Pagamento = () => {
  return (
    <div className='main-content m-0 bg-light-blue pagina-pricing-registration'>
      <div className='page-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center d-flex justify-content-center'>
                <a className='mb-5 d-block auth-logo'>
                  <img src={ImgLogo} alt='' className='logo logo-dark' />
                </a>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-5'>
              <div className='text-center mb-5'>
                <h4>Seleziona il tuo piano</h4>
                <p className='text-muted mb-4'>
                  Scegli il piano più adatto alle tue esigenze
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-6'>
              <div className='card'>
                <div className='card-body p-4'>
                  <div className='text-center mt-2'>
                    <h5 className='text-primary'>Piano Standard</h5>
                    <p className='text-muted'>Dettagli pagamento</p>
                  </div>
                  <div className='p-2 mt-4'>
                    <form action=''>
                      <div className='row mb-4'>
                        <div className='col-7'>
                          <div className='form-outline'>
                            <label className='form-label' for='formControlLgXM'>
                              Titolare della carta
                            </label>
                            <input
                              type='text'
                              id='formControlLgXM'
                              className='form-control form-control-lg'
                              placeholder='Il tuo nome'
                            />
                          </div>
                        </div>
                        <div className='col-3'>
                          <div className='form-outline'>
                            <label
                              className='form-label'
                              for='formControlLgExpk'
                            >
                              Expiration
                            </label>
                            <input
                              type='datetime-local'
                              id='formControlLgExpk'
                              className='form-control form-control-lg'
                              placeholder='MM/YYYY'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-row align-items-center mb-4 pb-1'>
                        <img
                          className='img-fluid'
                          src='https://img.icons8.com/color/48/000000/mastercard-logo.png'
                        />
                        <div className='d-flex mx-3'>
                          <div className='form-outline'>
                            <label className='form-label' for='formControlLgXc'>
                              Card Number
                            </label>
                            <input
                              type='text'
                              id='formControlLgXc'
                              className='form-control form-control-lg'
                              value='**** **** **** 3193'
                            />
                          </div>
                        </div>
                        <div className='form-outline'>
                          <label className='form-label' for='formControlLgcvv'>
                            CVV
                          </label>
                          <input
                            type='password'
                            id='formControlLgcvv'
                            className='form-control form-control-lg'
                            placeholder='Cvv'
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='card-footer p-4'>
                  <div className='row justify-content-evenly'>
                    <div className='col-auto d-flex align-items-end gap-1'>
                      <h3 className='m-0'>83,93</h3>
                      <p className='p-0 m-0'>all'anno</p>
                    </div>
                    <div className='col-auto d-flex align-items-end'>
                      <a href='' className='btn bg-verde text-white'>
                        Acquista
                      </a>
                    </div>
                    <div className='col-12 text-center mt-5'>
                      <small className='lead fs-6'>
                        Accetto <b>Le condizioni</b> e <b>la Privacy Policy</b>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagamento;
