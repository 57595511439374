import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../StateContainer";
import { useParams } from "react-router-dom";
import { BsPlusCircleFill as Aggiungi } from "react-icons/bs";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { IoRefresh as Refresh } from "react-icons/io5";
import Moment from "react-moment";
import "moment/locale/it";
import axios from "axios";
import ModalNotificationError from "../Modals/ModalNotificationError";
import FaqCaricatiMobile from "./Mobile/FaqCaricateMobile";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Editor, EditorState } from "draft-js";
import { IoMdCloseCircle as Close } from "react-icons/io";

const QuestionsAndAnswers = ({ dettagliProgetto }) => {
  const {
    dominioDelSito,
    setDominioDelSito,
    linkDiretto,
    setLinkDiretto,
    loading,
    setLoading,
    error,
    setError,
    token,
    messageError,
    aggiornaDomanda,
    setAggiornaDomanda,
    aggiornamentoDatiProgetto,
    aggiornaRisposta,
    setAggiornaRisposta,
    setMessageError,
  } = useContext(StateContext);

  const { id } = useParams();
  const [isUpdatingFaq, setIsUpdatingFaq] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const [risposta, setRisposta] = useState("");
  const [domanda, setDomanda] = useState("");

  const [faqRecived, setFaqRecived] = useState([]);
  const [faqList, setFaqList] = useState([{ question: "", answer: "" }]);
  const [erroreCaricamentoFaq, setErroreCaricamentoFaq] = useState(false);

  const [azioniFaq, setAzioniFaq] = useState([]);

  const CancellaModifiche = () => {
    setAggiornaDomanda("");
    setAggiornaRisposta("");
  };

  console.log(risposta);

  const CaricaFaq = () => {
    setErroreCaricamentoFaq(false);
    let rispostaAssistant = risposta
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "<br>")
      .replace(/^<br>/, "")
      .replace(/<br>$/, "")
      .trim();

    let listaFaq = [{ question: domanda, answer: rispostaAssistant }];
    setIsUpdatingFaq(true);
    const apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/add_faq`;

    if (domanda === "" || risposta === "") {
      setIsUpdatingFaq(false);
      setMessageError(
        "Devi compilare almeno una Q&A prima di procedere al caricamento."
      );
      setErroreCaricamentoFaq(true);
      return;
    }
    setDomanda("");
    setRisposta("");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
      body: JSON.stringify({
        projectCode: id,
        token: token,
        faq: listaFaq,
        actions: azioniFaq,
      }),
    };

    fetch(apiURL, requestOptions)
      .then((response) => {
        setIsUpdatingFaq(false);
        setAzioniFaq([]);
        console.log(response);
        console.log(response.status);
        if (response.status === 500) {
          setMessageError(
            "C'è stato un problema con il caricamento dei dati: errore\r\n" +
              response.status
          );
          setErroreCaricamentoFaq(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setErroreCaricamentoFaq(true);
        setMessageError(err.message);
        setIsUpdatingFaq(false);
      });
  };

  const EliminaFaq = (codiceFaq) => {
    setIsUpdatingFaq(true);
    const apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/remove_faq`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
      body: JSON.stringify({
        projectCode: id,
        code: codiceFaq,
        token: token,
      }),
    };

    fetch(apiURL, requestOptions)
      .then((response) => {
        setIsUpdatingFaq(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AggiornaFaq = (codiceFaq, faqAnswer, faqQuestion, faqActions) => {
    setIsUpdatingFaq(true);
    const apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/update_faq`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
      body: JSON.stringify({
        projectCode: id,
        code: codiceFaq,
        token: token,
        question: aggiornaDomanda || faqQuestion,
        answer: aggiornaRisposta || faqAnswer,
        // actions: faqActions,
        actions: faqActions,
      }),
    };

    fetch(apiURL, requestOptions)
      .then((response) => {
        setIsUpdatingFaq(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [arrayAzioni, setArrayAzioni] = useState([]);

  useEffect(() => {
    setDominioDelSito("");
    setLoading(true);

    // Funzione per la chiamata API
    const fetchData = () => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          projectCode: id,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            window.location.replace("/login");
          } else {
            setLoading(false);
            if (response.data.response.links !== faqRecived) {
              setFaqRecived(response.data.response.faqs);
              console.log(response.data.response.faqs);
            }

            setError("");
          }
        })
        .catch((err) => setError(err));
    };

    // Esegui l'effetto una sola volta all'inizio
    if (!isUpdatingFaq) {
      fetchData();
    } else {
      // Esegui l'effetto ogni 5 secondi
      const interval = setInterval(fetchData, 5000);

      // Pulisci l'intervallo quando l'effetto viene rimosso
      return () => clearInterval(interval);
    }
  }, [isUpdatingFaq, aggiornamentoDatiProgetto]);

  console.log(azioniFaq);

  const AggiungiAzioniFaq = () => {
    setAzioniFaq((prev) => [
      ...prev,
      { "etichetta": "", "type": "default", "content": "" },
    ]);
  };

  const EliminaAzione = (index) => {
    setAzioniFaq((prev) => prev.filter((el, i) => i !== index));
  };

  // Update delle Azioni
  const UpdateAzioni = (index, value, casella) => {
    const updateAzioni = [...azioniFaq];
    if (casella === "etichetta") {
      updateAzioni[index].etichetta = value;
    } else if (casella === "type") {
      updateAzioni[index].type = value;
    } else {
      updateAzioni[index].content = value;
    }

    setAzioniFaq(updateAzioni);
  };

  // Update delle Azioni
  const UpdateAzioniUpdate = (indexFaq, index, value, casella) => {
    const updateAzioni = [...faqRecived];
    if (casella === "etichetta") {
      updateAzioni[indexFaq].actions[index].etichetta = value;
    } else if (casella === "type") {
      updateAzioni[indexFaq].actions[index].type = value;
    } else {
      updateAzioni[indexFaq].actions[index].content = value;
    }

    setArrayAzioni(updateAzioni);
  };

  const AggiungiAzioniFaqUpdate = () => {
    setFaqRecived((prev) => {
      return prev.map((item) => {
        if (item.actions) {
          // Se il sottoarray 'actions' esiste, aggiungi il nuovo oggetto
          return {
            ...item,
            actions: [
              ...item.actions,
              { "etichetta": "", "type": "default", "content": "" },
            ],
          };
        } else {
          // Se il sottoarray 'actions' non esiste, crea un nuovo oggetto con 'actions' contenente il nuovo oggetto
          return {
            ...item,
            actions: [{ "etichetta": "", "type": "default", "content": "" }],
          };
        }
      });
    });
  };

  const RemoveAzioniFaqUpdate = (indexToRemove) => {
    setFaqRecived((prev) =>
      prev.map((el) => ({
        ...el,
        actions: el.actions.filter((_, index) => index !== indexToRemove),
      }))
    );
  };

  return (
    <div className='container position-relative h-100 d-flex flex-column card_content'>
      <div className='row'>
        <div className='col-12'>
          <p className='text-muted'>
            In questa sezione puoi caricare un elenco di domande / risposta.
            Quando l'utente chiede all'assistente una domanda simile ad una di
            quelle disponibili nell'elenco, il sistema fornisce la risposta
            relativa, senza utilizzare gli altri contenuti caricati.
          </p>
        </div>
        <div className='col-12 mt-3' id='faqContainer'>
          <div className='divDomanda'>
            <label htmlFor={`inputDomanda`}>Domanda</label>
            <input
              id={`inputDomanda`}
              type='text'
              placeholder='La tua domanda'
              value={domanda}
              onChange={(event) => setDomanda(event.target.value)}
              className='w-100 p-2 mt-2 rounded inputDomandaQA'
            />
          </div>

          <div className='divRisposta mt-3'>
            <label htmlFor={`inputRisposta`}>Risposta</label>
            <ReactQuill
              theme='snow'
              id={`inputRisposta`}
              placeholder='La tua risposta'
              value={risposta}
              onChange={setRisposta}
              className='border-0 w-100 h-auto my-2 position-relative inputRispostaQA'
            />
          </div>
        </div>
      </div>

      {/* Sezione Aggiungi Azioni FAQ  */}

      <div className='row d-flex flex-fill h-auto'>
        <div className='col-12'>
          <button
            onClick={AggiungiAzioniFaq}
            className='btn bg-verde text-white w-25 my-3'
          >
            + Aggiungi Azione
          </button>
        </div>
        {azioniFaq.map((azione, index) => {
          return (
            <div
              className='col-12 gap-2 d-flex flex-column align-items-center justify-content-center my-2'
              key={index}
            >
              <div className='d-flex justify-content-between align-items-center w-100'>
                <h6>Azione #{index + 1}</h6>
                <a
                  className='fs-2 text-danger'
                  style={{ cursor: "pointer" }}
                  onClick={() => EliminaAzione(index)}
                >
                  <Close />
                </a>
              </div>
              <input
                type='text'
                placeholder='Inserisci Etichetta'
                value={azione.etichetta}
                onChange={(e) =>
                  UpdateAzioni(index, e.target.value, "etichetta")
                }
                className='w-100 p-2 rounded inputDomandaQA'
              />
              <select
                className='form-select p-2'
                aria-label='Select'
                value={azione.type}
                onChange={(e) => UpdateAzioni(index, e.target.value, "type")}
              >
                <option value='default'>Nessuna Opzione Selezionata</option>
                <option value='talk'>Talk</option>
                <option value='link'>Link</option>
              </select>
              {azione.type === "link" ? (
                <input
                  type={"text"}
                  className='w-100 p-2 rounded form-control'
                  placeholder='Es: https://www.sito.it/'
                  value={azione.content}
                  onChange={(e) =>
                    UpdateAzioni(index, e.target.value, "content")
                  }
                />
              ) : (
                <textarea
                  type={"text"}
                  className='w-100 p-2 rounded form-control'
                  placeholder="Inserisci la domanda da fare all'assistente"
                  rows='5'
                  value={azione.content}
                  onChange={(e) =>
                    UpdateAzioni(index, e.target.value, "content")
                  }
                />
              )}
              <hr className='w-100'></hr>
            </div>
          );
        })}
      </div>

      {/* Fine Sezione Aggiungi Azioni FAQ  */}

      <div className='row d-flex flex-fill h-auto'>
        <div className='col-12 gap-2 d-flex align-items-end justify-content-end'>
          {/* <button className='btn text-white bg-rosso'>Annulla</button> */}
          <button
            className={`btn text-white bg-green-second w-auto py-2 mt-2 carica-faq ${
              isUpdatingFaq ? "disabled" : ""
            }`}
            onClick={CaricaFaq}
          >
            Carica
          </button>
        </div>
      </div>
      <div className='col-12 my-4'>
        {isUpdatingFaq ? (
          <div
            className='alert alert-warning d-flex align-items-center gap-2'
            role='alert'
          >
            <div className='spinner-border fs-6' role='status'>
              <span className='sr-only'></span>
            </div>
            Sto caricando le FAQ, attendere un momento ...
          </div>
        ) : (
          ""
        )}
        <table className='table rounded d-none d-xl-table'>
          <thead className='bg-secondary text-white'>
            <tr>
              <th scope='col'></th>
              <th scope='col'>Domanda</th>
              <th scope='col'>Risposta</th>
              <th scope='col'>Data ultima interazione</th>
              <th scope='col'>Numero di caratteri</th>
              <th scope='col'>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {faqRecived.length === 0 ? (
              <tr className='text-center'>
                <td colSpan={6} className='py-4'>
                  Nessuna Q/A inserita
                </td>
              </tr>
            ) : (
              faqRecived.map((faq, indexFaq) => {
                return (
                  <React.Fragment key={indexFaq}>
                    <tr key={indexFaq}>
                      <th scope='row' className='align-middle'>
                        {indexFaq + 1}
                      </th>

                      <td className='align-middle'>{faq.question}</td>
                      <td
                        className='align-middle'
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      ></td>

                      <td className='align-middle'>
                        <Moment format='DD MMMM YYYY, HH:mm' locale='it'>
                          {faq.updated.date}
                        </Moment>
                      </td>
                      <td className='align-middle'>
                        {faq.charsCount.toLocaleString("it-IT")}
                      </td>

                      <td className='text-center align-middle'>
                        <div className='d-flex gap-2 justify-content-start align-self-center'>
                          <button
                            className='btn btn-danger text-white px-2 fs-5 d-flex align-items-center'
                            onClick={() => EliminaFaq(faq.code)}
                            title='Elimina Domanda e risposta'
                          >
                            <Cestino></Cestino>
                          </button>
                          <button
                            className='btn btn-primary text-white p-0 px-2 fs-5 d-flex align-items-center'
                            title='Aggiorna Domanda e risposta'
                            data-bs-toggle='collapse'
                            aria-haspopup='true'
                            aria-expanded='false'
                            aria-controls={`aggiornaFaq-${indexFaq}`}
                            href={`#aggiornaFaq-${indexFaq}`}
                            onClick={CancellaModifiche}
                          >
                            <Matita></Matita>
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr id={`aggiornaFaq-${indexFaq}`} className='collapse'>
                      <td
                        colSpan={7}
                        className='collapse'
                        id={`aggiornaFaq-${indexFaq}`}
                      >
                        <div
                          id={`aggiornaFaq-${indexFaq}`}
                          className='collapse'
                        >
                          <div key={indexFaq} className='divDomanda py-2'>
                            <label htmlFor={`inputDomanda-${indexFaq}`}>
                              Domanda
                            </label>
                            <input
                              id={`inputDomanda-${indexFaq}`}
                              type='text'
                              value={aggiornaDomanda || faq.question}
                              onChange={(event) =>
                                setAggiornaDomanda(event.target.value)
                              }
                              className='w-100 p-2 mt-2 rounded inputSitoWeb'
                            />
                          </div>
                          <div className='divRisposta py-2'>
                            <label htmlFor={`inputRisposta-${indexFaq}`}>
                              Risposta
                            </label>

                            <textarea
                              id={`inputRisposta-${indexFaq}`}
                              placeholder={faq.answer}
                              value={aggiornaRisposta || faq.answer}
                              onChange={(event) =>
                                setAggiornaRisposta(event.target.value)
                              }
                              onClick={(event) =>
                                setAggiornaRisposta(event.target.value)
                              }
                              className='w-100 p-2 mt-2 rounded inputSitoWeb'
                            />
                          </div>
                          <div key={indexFaq} className='py-2'>
                            <h6>Azioni</h6>

                            <button
                              onClick={AggiungiAzioniFaqUpdate}
                              className='btn bg-verde text-white w-25 my-3'
                            >
                              + Aggiungi Azione
                            </button>
                            {faq.actions &&
                              faq.actions.map((azione, index) => {
                                return (
                                  <div
                                    className='col-12 gap-2 d-flex flex-column align-items-center justify-content-center my-2'
                                    key={index}
                                  >
                                    <div className='d-flex justify-content-between align-items-center w-100'>
                                      <h6>Azione #{index + 1}</h6>
                                      <a
                                        className='fs-2 text-danger'
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          RemoveAzioniFaqUpdate(index)
                                        }
                                      >
                                        <Close />
                                      </a>
                                    </div>
                                    <input
                                      type='text'
                                      placeholder='Inserisci Etichetta'
                                      value={azione.etichetta}
                                      onChange={(e) =>
                                        UpdateAzioniUpdate(
                                          indexFaq,
                                          index,
                                          e.target.value,
                                          "etichetta"
                                        )
                                      }
                                      className='w-100 p-2 rounded inputDomandaQA'
                                    />
                                    <select
                                      className='form-select p-2'
                                      aria-label='Select'
                                      value={azione.type}
                                      onChange={(e) =>
                                        UpdateAzioniUpdate(
                                          indexFaq,
                                          index,
                                          e.target.value,
                                          "type"
                                        )
                                      }
                                    >
                                      <option value='default'>
                                        Nessuna Opzione Selezionata
                                      </option>
                                      <option value='talk'>Talk</option>
                                      <option value='link'>Link</option>
                                    </select>
                                    {azione.type === "link" ? (
                                      <input
                                        type={"text"}
                                        className='w-100 p-2 rounded form-control'
                                        placeholder='Es: https://www.sito.it/'
                                        value={azione.content}
                                        onChange={(e) =>
                                          UpdateAzioniUpdate(
                                            indexFaq,
                                            index,
                                            e.target.value,
                                            "content"
                                          )
                                        }
                                      />
                                    ) : (
                                      <textarea
                                        type={"text"}
                                        className='w-100 p-2 rounded form-control'
                                        placeholder="Inserisci la domanda da fare all'assistente"
                                        rows='5'
                                        value={azione.content}
                                        onChange={(e) =>
                                          UpdateAzioniUpdate(
                                            indexFaq,
                                            index,
                                            e.target.value,
                                            "content"
                                          )
                                        }
                                      />
                                    )}
                                    <hr className='w-100'></hr>
                                  </div>
                                );
                              })}
                          </div>
                          <div className='row d-flex flex-fill h-auto my-3'>
                            <div className='col-12 gap-2 d-flex align-items-end justify-content-end'>
                              <button
                                className={`btn text-white bg-green-second`}
                                title='Aggiorna Domanda e risposta'
                                data-bs-toggle='collapse'
                                aria-haspopup='true'
                                aria-expanded='false'
                                aria-controls={`aggiornaFaq-${indexFaq}`}
                                href={`#aggiornaFaq-${indexFaq}`}
                                onClick={() =>
                                  AggiornaFaq(
                                    faq.code,
                                    faq.answer,
                                    faq.question,
                                    faq.actions
                                  )
                                }
                              >
                                Aggiorna
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
        <div className='d-block d-xl-none'>
          {faqRecived.length === 0 ? (
            <tr className='text-center'>
              <td colSpan={5} className='py-4'>
                Nessuna Q&A è stata ancora caricata
              </td>
            </tr>
          ) : (
            faqRecived.map((faq, index) => {
              return (
                <FaqCaricatiMobile
                  faq={faq}
                  index={index}
                  EliminaFaq={EliminaFaq}
                  AggiornaFaq={AggiornaFaq}
                  AggiungiAzioniFaqUpdate={AggiungiAzioniFaqUpdate}
                  UpdateAzioniUpdate={UpdateAzioniUpdate}
                  RemoveAzioniFaqUpdate={RemoveAzioniFaqUpdate}
                ></FaqCaricatiMobile>
              );
            })
          )}
        </div>
        {erroreCaricamentoFaq ? (
          <ModalNotificationError
            title={"Si è verificato un errore"}
            message={messageError}
            FunctionRemoveModal={setErroreCaricamentoFaq}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default QuestionsAndAnswers;
