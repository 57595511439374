import React, { useState } from "react";
import { ImAttachment } from "react-icons/im";
import "../../App.css";

const DragAndDrop = ({ onDrop, setSelectedFile, isUploadingDocument }) => {
  const [selectedFileName, setSelectedFileName] = useState("");

  const [isDrag, setIsDrag] = useState(false);
  const [files, setFiles] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
    setIsDrag(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDrag(false);
  };

  const handlePaste = (e) => {
    const files = e.clipboardData.files[0];
    setSelectedFile(files);
    setSelectedFileName(files.name);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedFile = e.dataTransfer.files[0];
    setSelectedFile(selectedFile);
    setSelectedFileName(selectedFile.name); // Imposta il nome del file
    setIsDrag(false);
  };

  return (
    <>
      <h5 className='card-title my-3 text-center'>
        Seleziona File da caricare
      </h5>
      <div className='col-auto mb-2'>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onPaste={handlePaste}
          onDragLeave={handleDragLeave}
          className={`drag-drop-container text-center p-4 ${
            isDrag ? "drag-over" : ""
          }`}
          style={{ borderStyle: "dotted", border: "0.5px grey dotted" }}
        >
          <div className='drag-drop-text py-3'>
            {isDrag
              ? "Rilascia qui il file"
              : 'Clicca il tasto "Scegli file" oppure trascina e rilascia in questo riquadro.'}
          </div>
          <input
            type='file'
            multiple
            accept='application/pdf, text/plain, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentation, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            id='inputFileDocument'
            className={`form-control`}
            disabled={isUploadingDocument ? true : false}
            onChange={onDrop}
          />
          {/* Mostra il nome del file selezionato */}
          {selectedFileName && (
            <p className='mt-3'>File selezionato: {selectedFileName}</p>
          )}
        </div>
        {alertMessage ? <p className='text-danger mt-2'>{alertMessage}</p> : ""}
      </div>
    </>
  );
};

export default DragAndDrop;
