import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../StateContainer";
import { useParams } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import "moment/locale/it";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { IoRefresh as Refresh } from "react-icons/io5";
import ModalCreaProgetto from "../Modals/ModalCreaProgetto";
import ModalSuccess from "../Modals/ModalSuccess";
import ModalNotificationError from "../Modals/ModalNotificationError";
import SitiCrawlatiMobile from "./Mobile/SitiCrawlatiMobile";

const SitoWebUpload = () => {
  const {
    dominioDelSito,
    setDominioDelSito,
    linkDiretto,
    setLinkDiretto,
    error,
    setError,
    setMessageError,
    messageError,
    textOfTheLink,
    setTextOfTheLink,
    currentIndex,
    setCurrentIndex,
  } = useContext(StateContext);

  const { id } = useParams();
  const [isCrawlable, setIsCrawlable] = useState(true);
  const [erroreCaricamentoSito, setErroreCaricamentoSito] = useState(false);
  const [isDeletingSite, setIsDeletingSite] = useState(false);
  const [isCrawling, setIsCrawling] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [isGettingDocument, setIsisGettingDocument] = useState(false);
  const [fileCaricati, setFileCaricati] = useState([]);
  const [statoCaricamentoFile, setStatoCaricamentoFile] = useState(0);
  const [isNotCancelled, setIsNotCancelled] = useState(true);
  const [linkSitemap, setLinkSitemap] = useState("");

  // Messaggi di errore nel Crawling

  const { setLoading, token, aggiornamentoDatiProgetto } =
    useContext(StateContext);

  const handleKeyDownDiretto = (event) => {
    if (event.key === "Enter") {
      CaricaUrlSitoWebDiretto();
    }
  };

  const CaricaUrlSitoWebDiretto = () => {
    setIsCrawlable(true);
    let siteDomain = linkDiretto;
    setLinkDiretto("");
    setLoading(true);
    setIsCrawling(true);

    if (siteDomain === "") {
      console.log("sono qui");
      setMessageError(
        "Devi inserire il link di un sito prima di procedere al caricamento."
      );
      setErroreCaricamentoSito(true);
      setLoading(false);
      setIsCrawling(false);
      return;
    }

    axios({
      method: "get",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/link_diretto?url=${siteDomain}&projectCode=${id}&token=${token}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
          setErroreCaricamentoSito(true);
        } else {
          setLoading(false);
          setIsCrawling(false);
          setErroreCaricamentoSito(false);
          if (response.data.error) {
            setMessageError(response.data.error);
            setErroreCaricamentoSito(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setErroreCaricamentoSito(true);
        setMessageError(err.message);
        setIsCrawling(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      CaricaUrlSitoWeb();
    }
  };

  // Crawling Completo
  const CaricaUrlSitoWeb = () => {
    setErroreCaricamentoSito(false);
    setMessageError("");
    setIsCrawlable(true);
    let siteDomain = dominioDelSito;
    setDominioDelSito("");
    setLoading(true);
    setIsCrawling(true);

    console.log(siteDomain);

    if (siteDomain === "") {
      console.log("sono qui");
      setMessageError(
        "Devi inserire il link di un sito prima di procedere al caricamento."
      );
      setErroreCaricamentoSito(true);
      setLoading(false);
      setIsCrawling(false);
      return;
    }

    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_PYTHON_ENGINE
      }/siteUrl?url=${encodeURIComponent(
        siteDomain
      )}&projectCode=${encodeURIComponent(id)}&token=${encodeURIComponent(
        token
      )}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
          setErroreCaricamentoSito(true);
        } else {
          setLoading(false);
          setIsCrawling(false);
          setErroreCaricamentoSito(false);
          if (response.data.error) {
            setMessageError(response.data.error);
            setErroreCaricamentoSito(true);
          }
        }
      })
      .catch((err) => {
        setErroreCaricamentoSito(true);
        setMessageError(err.message);
        setIsCrawling(false);
      });
  };

  const [linkCrawlati, setLinkCrawlati] = useState([]);

  useEffect(() => {
    setDominioDelSito("");
    setLoading(true);

    // Funzione per la chiamata API
    const fetchData = () => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          projectCode: id,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            window.location.replace("/login");
          } else {
            setLoading(false);
            if (response.data.response.links !== linkCrawlati) {
              setLinkCrawlati(response.data.response.links);
            }
          }
        })
        .catch((err) => setErroreCaricamentoSito(true));
    };

    // Esegui l'effetto una sola volta all'inizio
    if (!isCrawling && isNotCancelled) {
      fetchData();
    } else {
      // Esegui l'effetto ogni 5 secondi
      const interval = setInterval(fetchData, 5000);

      // Pulisci l'intervallo quando l'effetto viene rimosso
      return () => clearInterval(interval);
    }
  }, [isCrawling, isUpdated, isNotCancelled, aggiornamentoDatiProgetto]);

  //https://www.fulgeas.com/

  const EliminaUrlSito = (deleteCode) => {
    setLoading(true);
    setIsDeletingSite(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/deleteUrl?deleteCode=${deleteCode}&projectCode=${id}&token=${token}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setLoading(false);
          setIsDeletingSite(false);
          if (response.data.status == 200) {
            const index = linkCrawlati.findIndex(
              (el) => el.code === response.data.code
            );
            if (index !== -1) {
              linkCrawlati.splice(index, 1);
            }
          }
        }
      })
      .catch((err) => setErroreCaricamentoSito(true));
  };

  const AggiornaUrlSito = (url, code) => {
    setIsUpdated(true);
    setErroreCaricamentoSito(false);
    setLoading(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/update_link?update_code=${code}&projectCode=${id}&token=${token}&url=${url}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setLoading(false);
          setIsUpdated(false);
          setTimeout(() => {
            setAggiornamentoLink(true);
          }, 500);
        }
      })
      .catch((err) => setErroreCaricamentoSito(true));
  };

  let updateProgress = 0;

  const handleKeyDownSitemap = (event) => {
    if (event.key === "Enter") {
      UploadSitemap();
    }
  };

  const UploadSitemap = () => {
    setIsCrawlable(true);
    let siteDomain = linkSitemap;
    setLinkSitemap("");
    setLoading(true);
    setIsCrawling(true);

    if (siteDomain === "") {
      console.log("sono qui");
      setMessageError(
        "Devi inserire il link della sitemap di un sito prima di procedere al caricamento."
      );
      setErroreCaricamentoSito(true);
      setLoading(false);
      setIsCrawling(false);
      return;
    }

    axios({
      method: "get",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/upload_sitemap?url=${siteDomain}&projectCode=${id}&token=${token}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setLoading(false);
          setIsCrawling(false);
          setErroreCaricamentoSito(false);
        }
      })
      .catch((err) => {
        setErroreCaricamentoSito(true);
        setMessageError(err.message);
        setIsCrawling(false);
      });
  };

  const StopProcesso = () => {
    setIsNotCancelled(false);

    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/stopProcess`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        projectCode: id,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setLoading(false);
          setIsNotCancelled(true);
        }
      })
      .catch((err) => setError(true));
  };

  const [aggiornamentoLink, setAggiornamentoLink] = useState(false);

  const GetLinkText = (code, index) => {
    setCurrentIndex(index);
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/get-link-text`,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: JSON.stringify({
        projectCode: id,
        code: code,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setTextOfTheLink(response.data.response.text);
        }
      })
      .catch((err) => setError(true));
  };

  const AggiornaTestoLink = (code, textOfTheLink, url) => {
    setAggiornamentoLink(false);
    setIsUpdated(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/update_text_link`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        "token": token,
        "projectCode": id,
        "code": code,
        "text_link": textOfTheLink,
        "url": url,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setCurrentIndex(null);
          setIsUpdated(false);
          setTimeout(() => {
            setAggiornamentoLink(true);
          }, 500);
        }
      })
      .catch((err) => setError(true));
  };

  return (
    <div className='container position-relative h-100 d-flex flex-column card_content'>
      {isCrawling && !erroreCaricamentoSito ? (
        <div className='row'>
          <div
            className='alert alert-warning d-flex align-items-center gap-2'
            role='alert'
          >
            <div className='spinner-border fs-6' role='status'>
              <span className='sr-only'></span>
            </div>
            Scansione del sito in corso, attendere ...
          </div>
        </div>
      ) : (
        ""
      )}

      <div className='row justify-content-between'>
        <div className='col-12 col-xl-8'>
          <div className='d-flex align-items-center gap-1'>
            <label htmlFor='inputSitoWeb'>Scansione del Sito Web</label>
          </div>

          <input
            id='inputSitoWeb'
            type='text'
            className='w-100 p-2 my-3 mt-xl-2 mb-xl-0 rounded-pill inputSitoWeb'
            placeholder='https://www.esempio.com/'
            value={dominioDelSito}
            onChange={(event) => setDominioDelSito(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          {!isCrawlable ? (
            <p className='text-danger my-2'>Hai già caricato questo sito.</p>
          ) : (
            ""
          )}
        </div>

        <div className='col-auto gap-2 d-flex align-items-end justify-content-end flex-fill'>
          <button
            className='btn text-white bg-rosso w-100 w-xl-auto'
            onClick={StopProcesso}
          >
            Annulla
          </button>
          <a
            className={`btn text-white bg-green-second  w-100 w-xl-auto ${
              isCrawling ? "disabled" : ""
            }`}
            type='button'
            onClick={CaricaUrlSitoWeb}
            href='#tableSiteUpload'
          >
            Carica
          </a>
        </div>
        <p className='text-muted mt-3'>
          Inserisci il dominio del tuo sito internet. Il motore di acquisizione
          seguirà i link interni e scansionerà le pagine interne del sito. La
          scansione si interrompe dopo 200 pagine. Per un controllo più accurato
          utilizza il caricamento con sitemap.
        </p>
      </div>

      {/* Inizia qui */}

      <div className='d-flex align-items-center'>
        <hr className='w-100' />
        <p className='m-0 p-4'>Oppure</p>
        <hr className='w-100' />
      </div>

      <div className='row d-flex my-4'>
        <div className='col-12 col-xl-8'>
          <div className='d-flex align-items-center gap-1'>
            <label htmlFor='inputSitoWeb'>
              Scansiona una singola pagina web
            </label>
          </div>

          <input
            id='inputSitoWeb'
            type='text'
            className='w-100 p-2 mt-2 rounded-pill inputSitoWeb'
            placeholder='https://www.esempio.com/'
            value={linkDiretto}
            onChange={(event) => setLinkDiretto(event.target.value)}
            onKeyDown={handleKeyDownDiretto}
          />
          {!isCrawlable ? (
            <p className='text-danger my-2'>Hai già caricato questo sito.</p>
          ) : (
            ""
          )}
        </div>

        <div className='col-auto flex-fill gap-2 d-flex align-items-end justify-content-end'>
          <div className='w-100 d-none d-xl-block'></div>
          <a
            className={`btn text-white bg-green-second w-100 py-2 my-3 my-xl-0 ${
              isCrawling ? "disabled" : ""
            }`}
            role='button'
            href='#tableSiteUpload'
            onClick={CaricaUrlSitoWebDiretto}
          >
            Carica
          </a>
        </div>
        <p className='text-muted mt-0 mt-xl-3'>
          Viene acquisita la pagine web del link fornito.
        </p>
      </div>

      {/* Finisce qui */}

      {/* Sitemap Inizia qui */}

      <div className='d-flex align-items-center'>
        <hr className='w-100' />
        <p className='m-0 p-4'>Oppure</p>
        <hr className='w-100' />
      </div>

      <div className='row d-flex my-4'>
        <div className='col-12 col-xl-8'>
          <div className='d-flex align-items-center gap-1'>
            <label htmlFor='inputSitemap'>Caricamento della Sitemap</label>
          </div>

          <input
            id='inputSitoWeb'
            type='text'
            className='w-100 p-2 mt-2 rounded-pill inputSitoWeb'
            placeholder='https://www.esempio.com/sitemap.xml'
            value={linkSitemap}
            onChange={(event) => setLinkSitemap(event.target.value)}
            onKeyDown={handleKeyDownSitemap}
          />
          {!isCrawlable ? (
            <p className='text-danger my-2'>Hai già caricato questo sito.</p>
          ) : (
            ""
          )}
        </div>

        <div className='col-auto flex-fill gap-2 d-flex align-items-end justify-content-end'>
          {/* <button className='btn text-white bg-rosso'>Annulla</button> */}
          <button
            className='btn text-white bg-rosso w-100 w-xl-auto'
            onClick={StopProcesso}
          >
            Annulla
          </button>
          <a
            className={`btn text-white bg-green-second w-100 w-xl-auto mt-3 mt-xl-0 ${
              isUploadingDocument ? "disabled" : ""
            }`}
            role='button'
            href='#tableSiteUpload'
            onClick={UploadSitemap}
          >
            Carica
          </a>
        </div>
        <p className='text-muted mt-3'>
          Tutte le pagine web contenute nella sitemap vengono acquisite.
        </p>
      </div>

      {/* Sitemap Finisce qui */}

      <div className='row d-flex flex-fill h-auto align-items-center'>
        <div className='col-12 my-4'>
          <h6 className='m-0 p-0'>Elenco delle pagine web acquisite</h6>
        </div>
        <div className='col-12'>
          {isDeletingSite ? (
            <div
              className='alert alert-warning d-flex align-items-center gap-2'
              role='alert'
            >
              <div className='spinner-border fs-6' role='status'>
                <span className='sr-only'></span>
              </div>
              Sto eliminando la pagina web, attendere ...
            </div>
          ) : (
            ""
          )}
          {isUpdated ? (
            <div
              className='alert alert-warning d-flex align-items-center gap-2'
              role='alert'
              id='caricamentoAggiorna'
            >
              <div className='spinner-border fs-6' role='status'>
                <span className='sr-only'></span>
              </div>
              Sto aggiornando la pagina web, attendere ...
            </div>
          ) : (
            ""
          )}
          <table
            className='table rounded d-none d-xl-table'
            id='tableSiteUpload'
          >
            <thead className='bg-secondary text-white'>
              <tr>
                <th scope='col'></th>
                <th scope='col'>Pagina web del sito</th>
                <th scope='col'>Data ultima scansione</th>
                <th scope='col'>Numero di Caratteri</th>
                <th scope='col'>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {linkCrawlati.length === 0 ? (
                <tr className='text-center'>
                  <td colSpan={5} className='py-4'>
                    Nessun sito è stato ancora caricato
                  </td>
                </tr>
              ) : (
                linkCrawlati.map((link, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <th scope='row' className='align-middle'>
                          {index + 1}
                        </th>
                        <td className='align-middle'>{link.url}</td>
                        <td className='align-middle'>
                          <Moment format='DD MMMM YYYY, HH:mm' locale='it'>
                            {link.updated.date}
                          </Moment>
                        </td>
                        <td className='align-middle'>
                          {link.charsCount.toLocaleString("it-IT")}
                        </td>
                        <td className='text-center align-middle'>
                          <div className='d-flex gap-2 justify-content-center align-self-center'>
                            <button
                              className='btn btn-danger text-white p-0 px-1 fs-5'
                              onClick={() => EliminaUrlSito(link.code)}
                              title='Rimuovi pagina sito'
                            >
                              <Cestino></Cestino>
                            </button>
                            <button
                              className='btn bg-green-second text-white p-0 px-1 fs-5'
                              onClick={() =>
                                AggiornaUrlSito(link.url, link.code)
                              }
                              title='Aggiorna pagina sito'
                              href='#caricamentoAggiorna'
                            >
                              <Refresh></Refresh>
                            </button>
                            <button
                              className='btn bg-green-second text-white p-0 px-2 fs-5 d-flex align-items-center'
                              title='Aggiorna Domanda e risposta'
                              data-bs-toggle='collapse'
                              aria-haspopup='true'
                              aria-expanded='false'
                              aria-controls={`aggiornaLink-${index}`}
                              href={`#aggiornaLink-${index}`}
                              onClick={() => GetLinkText(link.code, index)}
                            >
                              <Matita></Matita>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr
                        id={`aggiornaLink-${index}`}
                        className={`collapsing ${
                          currentIndex === index ? "collapse show" : "collapse"
                        }`}
                      >
                        <td
                          colSpan={5}
                          id={`aggiornaLink-${index}`}
                          className={`collapsing ${
                            currentIndex === index
                              ? "collapse show"
                              : "collapse"
                          }`}
                        >
                          <div
                            id={`aggiornaLink-${index}`}
                            className={`collapsing ${
                              currentIndex === index
                                ? "collapse show"
                                : "collapse"
                            }`}
                          >
                            <div className='divRisposta py-2'>
                              <label htmlFor={`inputRisposta-${index}`}>
                                Testo Acquisito
                              </label>
                              <textarea
                                id={`inputRisposta-${index}`}
                                // placeholder={faq.answer}
                                value={textOfTheLink}
                                onChange={(event) =>
                                  setTextOfTheLink(event.target.value)
                                }
                                className='w-100 p-2 mt-2 rounded inputSitoWeb'
                                style={{ minHeight: "400px" }}
                              />
                              <div className='w-100 d-flex justify-content-end'>
                                <button
                                  className='btn text-white bg-green-second my-3'
                                  href='caricamentoAggiorna'
                                  onClick={() =>
                                    AggiornaTestoLink(
                                      link.code,
                                      textOfTheLink,
                                      link.url
                                    )
                                  }
                                >
                                  Aggiorna Testo Link
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </table>
          <div className='d-block d-xl-none'>
            {linkCrawlati.length === 0 ? (
              <tr className='text-center'>
                <td colSpan={5} className='py-4'>
                  Nessun sito è stato ancora caricato
                </td>
              </tr>
            ) : (
              linkCrawlati.map((link, index) => {
                return (
                  <SitiCrawlatiMobile
                    link={link}
                    index={index}
                    EliminaUrlSito={EliminaUrlSito}
                    GetLinkText={GetLinkText}
                    AggiornaUrlSito={AggiornaUrlSito}
                    AggiornaTestoLink={AggiornaTestoLink}
                  />
                );
              })
            )}
          </div>
          {aggiornamentoLink ? (
            <ModalSuccess
              title={"Aggiornamento Testo del Link"}
              message={"Il testo del Link è stato aggiornato Correttamente"}
              FunctionRemoveModal={setAggiornamentoLink}
            />
          ) : (
            ""
          )}

          {erroreCaricamentoSito ? (
            <ModalNotificationError
              title={"Si è verificato un errore"}
              message={messageError}
              FunctionRemoveModal={setErroreCaricamentoSito}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SitoWebUpload;
