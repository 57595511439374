import React, { useContext, useEffect } from "react";
import { StateContext } from "./StateContainer";
import { Link } from "react-router-dom";
import ModalCreaProgetto from "./Modals/ModalCreaProgetto";
import ModalEliminaProgetto from "./Modals/ModalEliminaProgetto";
import LogoTailor from "../img/logo-tailor.png";
import { BiHomeAlt2 as HomeIcon } from "react-icons/bi";
import Loading from "./Loading";
import axios from "axios";
const Navbar = () => {
  const state = useContext(StateContext);
  let verticalMenu = document.getElementById("sidebarMenu");
  const {
    nascondiSidebar,
    setNascondiSidebar,
    codiceProgetto,
    projectTitle,
    userPlan,
    userProfileData,
    profileLoading,
    setProfileLoading,
    setUserProfileData,
    windowWidth,
    token,
  } = state;

  useEffect(() => {
    setProfileLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/user/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setProfileLoading(false);
        setUserProfileData([response.data.response.user]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <header id='page-topbar' className=''>
        <div className='navbar-header d-flex justify-content-between px-3'>
          <div>
            {/* Aggiunta Icona della Hamburger */}
            <button
              type='button'
              className='btn btn-sm p-0'
              onClick={() => setNascondiSidebar(!nascondiSidebar)}
              style={{ outline: "none" }}
              data-bs-dismiss='offcanvas'
            >
              <img
                src={`${LogoTailor}`}
                alt=''
                style={{ height: "35px", width: "auto" }}
              />
            </button>

            {/* Aggiunta Icona della Homepage */}
            <button
              type='button'
              className='btn btn-sm px-3'
              onClick={() => setNascondiSidebar(!nascondiSidebar)}
              style={{ outline: "none" }}
              data-bs-dismiss='offcanvas'
            >
              {windowWidth <= 992 ? (
                <Link to='/' className='text-dark text-decoration-none'>
                  <HomeIcon
                    style={{ height: "30px", width: "auto" }}
                  ></HomeIcon>
                </Link>
              ) : (
                ""
              )}
            </button>
          </div>

          {/* Sezione dei Caratteri Totali e Residui */}
          <div className='d-flex gap-4 align-items-center'>
            <div>
              <div>
                <h6 className='font-size-13 m-0'>Caratteri Totali</h6>
              </div>
              <div className='text-end'>
                <h6 className='font-size-15 text-green-second font-size-20 m-0'>
                  {profileLoading ? (
                    <Loading />
                  ) : userProfileData[0] ? (
                    userProfileData[0].charsCount.toLocaleString()
                  ) : (
                    ""
                  )}
                </h6>
              </div>
            </div>
            <div>
              <div>
                <h6 className='font-size-13 m-0'>Crediti Rimasti</h6>
              </div>
              <div className='text-end'>
                <h6 className='font-size-15 text-green-second font-size-20 m-0'>
                  {profileLoading ? (
                    <Loading />
                  ) : userProfileData[0] ? (
                    userProfileData[0].creditsLeft.toLocaleString()
                  ) : (
                    ""
                  )}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div>
          <ModalCreaProgetto></ModalCreaProgetto>
          <ModalEliminaProgetto
            projectCode={codiceProgetto}
            projectTitle={projectTitle}
          ></ModalEliminaProgetto>
        </div>
      </header>
      <div>
        {windowWidth <= 992 && !nascondiSidebar ? (
          <div
            className='overlay d-flex align-items-center '
            id='overlaySidebarMobile'
            onClick={() => setNascondiSidebar(true)}
          ></div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Navbar;
