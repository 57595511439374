import React from "react";
import Moment from "react-moment";

const InfoDocumento = ({ index, doc }) => {
  return (
    <div
      className='modal fade overlay rounded-3 overflow-hidden border-0'
      id={`info-${index}`}
      tabIndex='-1'
      aria-labelledby={`infoLabel-${index}`}
      aria-hidden='true'
      data-bs-backdrop='false'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header border-0 pt-4 d-flex align-items-center justify-content-between'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              id='close-modal-create'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body pt-0 pb-3'>
            <div className='w-100'>
              <h6 className='fw-bold text-capitalize font-size-16'>
                Pagina web del sito
              </h6>
              <p className='d-flex gap-3 font-size-16'>
                <b>{index + 1}</b> {doc.title}
              </p>
            </div>
            <div className='row'>
              <div className='col-6 font-size-16'>
                <h6 className='fw-bold text-capitalize'>
                  Data ultima scansione
                </h6>
                <p className=''>
                  <Moment format='DD MMMM YYYY, HH:mm' locale='it'>
                    {doc.updated.date}
                  </Moment>
                </p>
              </div>
              <div className='col-6 font-size-16'>
                <h6 className='fw-bold text-capitalize'>Numero di caratteri</h6>
                <p className=''>{doc.charsCount}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoDocumento;
