import React, { useContext } from "react";
import IframeChatExample from "./IframeChatExample";
import { StateContext } from "../StateContainer";

const ChatPreview = ({
  progetto,
  currentFileImage,
  AvatarPink,
  activePanel,
}) => {
  const { assistantName, primaryColor } = useContext(StateContext);
  return (
    <div className='iframeContainerBefore iframeContainerSizes position-sticky w-auto '>
      <div
        className='iframe-chat-header'
        style={{ backgroundColor: `${primaryColor}` }}
      >
        <div className='d-flex align-items-center' style={{ gap: "10px" }}>
          <div
            className='iframe-chat-header-bot-image'
            style={{
              background: `url(${
                currentFileImage === null
                  ? (progetto[0] && progetto[0].chatbotIcon) || AvatarPink
                  : currentFileImage
              }) center center / cover no-repeat`,
            }}
          ></div>
          <div className='text-start iframe-chat-header-assistant-name'>
            <div className='m-0 fw-bold text-white'>{assistantName}</div>
            <p className='m-0 text-white'>Hai una domanda?</p>
          </div>
        </div>
        <button className='button-close-iframe-chat'>✕</button>
      </div>
      <IframeChatExample
        progetto={progetto}
        currentFileImage={currentFileImage}
        activePanel={activePanel}
      ></IframeChatExample>
    </div>
  );
};

export default ChatPreview;
