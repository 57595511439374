import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../StateContainer";
import axios from "axios";
import Cookies from "js-cookie";
import PoweredBy from "../../img/TailorGPTOriz.svg";

const FormGetUserDataPreview = ({ progetto, activePanel }) => {
  const {
    collectLeadName,
    setCollectLeadName,
    collectLeadEmail,
    setCollectLeadEmail,
    collectLeadCompany,
    primaryColor,
    setCollectLeadCompany,
    collectLeadJobTitle,
    setCollectLeadJobTitle,
    collectLeadPhone,
    setCollectLeadPhone,
    collectLeadMessage,
    setCollectLeadMessage,
    collectLeadWhen,
    setCollectLeadWhen,
    allowSkipContact,
    setAllowSkipContact,
    privacyLink,

    // Necessarie per aprire il form
    collectCustomerEmail,
    isSectionContactFormOpen,
  } = useContext(StateContext);

  const leadKeys = [
    {
      id: 1,
      text: "Nome",
      name: "nome_user",
      type: "text",
      name: "name",
      placeholder: "Nome",
      value: "",
      functionChange: setCollectLeadName,
      show:
        progetto && progetto.lead && progetto.lead.collectLeadName
          ? progetto.lead.collectLeadName
          : progetto && collectLeadName
          ? collectLeadName
          : "",
    },
    {
      id: 2,
      text: "Email",
      name: "email_user",
      type: "email",
      name: "email",
      placeholder: "Email",
      value: "",
      functionChange: setCollectLeadEmail,
      show:
        progetto && progetto.lead && progetto.lead.collectLeadEmail
          ? progetto.lead.collectLeadEmail
          : progetto && collectLeadEmail
          ? collectLeadEmail
          : "",
    },
    {
      id: 3,
      text: "Professione",
      name: "work_user",
      type: "text",
      name: "work",
      placeholder: "Professione",
      value: "",
      functionChange: setCollectLeadJobTitle,
      show:
        progetto && progetto.lead && progetto.lead.collectLeadJobTitle
          ? progetto.lead.collectLeadJobTitle
          : progetto && collectLeadJobTitle
          ? collectLeadJobTitle
          : "",
    },
    {
      id: 4,
      text: "Azienda",
      name: "company_user",
      type: "text",
      name: "company",
      placeholder: "Azienda",
      value: "",
      functionChange: setCollectLeadCompany,
      show:
        progetto && progetto.lead && progetto.lead.collectLeadCompany
          ? progetto.lead.collectLeadCompany
          : progetto && collectLeadCompany
          ? collectLeadCompany
          : "",
    },
    {
      id: 5,
      text: "Numero di Telefono",
      name: "nphone_user",
      type: "tel",
      name: "nphone",
      placeholder: "Numero di Telefono",
      value: "",
      functionChange: setCollectLeadPhone,
      show:
        progetto && progetto.lead && progetto.lead.collectLeadPhone
          ? progetto.lead.collectLeadPhone
          : progetto && collectLeadPhone
          ? collectLeadPhone
          : "",
    },
  ];
  let formData = {}; // Creiamo un oggetto per raccogliere i dati

  return (
    <>
      <div
        id='overlay'
        className={`d-grid align-items-center ${
          activePanel === 3 && collectCustomerEmail ? "" : "d-none"
        }`}
      >
        <form className={`form-get-email w-100 disabled`}>
          <p className='text-black fw-bold' id='message-form'>
            {progetto && progetto.lead && progetto.lead.collectLeadMessage
              ? progetto.lead.collectLeadMessage
              : progetto && collectLeadMessage
              ? collectLeadMessage
              : ""}
          </p>
          {leadKeys.map((lead) => (
            <React.Fragment key={lead.id}>
              {lead.show ? (
                <div className='form-group mt-3' key={lead.id}>
                  <label
                    htmlFor={lead.name}
                    className='text-black mb-2 fw-bold disabled'
                    value={lead.text}
                  >
                    {lead.text}
                  </label>
                  <input
                    type={lead.type}
                    className='form-control disabled'
                    id={lead.name}
                    placeholder={lead.placeholder}
                    name={lead.name}
                    // required
                    value={lead.value}
                    onChange={(e) => lead.functionChange(e.target.value)}
                  />
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          ))}
          <div className='d-flex align-items-center mt-5 justify-content-between '>
            <button
              className='btn text-white disabled'
              type='submit'
              id='inviaLeadsButton'
              style={{ background: `${primaryColor || progetto.primaryColor}` }}
            >
              Inizia Chat
            </button>
            {(progetto && progetto.lead && progetto.lead.allowSkipContact) ||
            (progetto && allowSkipContact) ? (
              <button
                id='skipFormButton'
                className='btn bg-transparent button-close-contact-form disabled'
              >
                Salta
              </button>
            ) : (
              ""
            )}
          </div>
        </form>
        <div className='w-100 h-100 d-flex align-items-end pb-5 justify-content-between my-1 px-4 banner-tailor bg-white'>
          <div className='my-1'>
            <a
              href='https://tailorgpt.ai/'
              target='_blank'
              className='text-decoration-none'
            >
              <img
                style={{
                  width: "auto",
                  height: "1.3vw",
                  minHeight: "15px",
                }}
                src={`${PoweredBy}`}
                alt=''
                className='img-fulgeas-chat'
              />
            </a>
          </div>
          {(progetto && progetto.privacy) || (progetto && privacyLink) ? (
            <div className='my-2 d-flex align-items-center'>
              <a
                className='font-size-11'
                style={{ color: "#535353" }}
                href={`${progetto.privacy || privacyLink}`}
                target='_blank'
              >
                Privacy
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default FormGetUserDataPreview;
