import React, { useContext, useState } from "react";
import { StateContext } from "../../../StateContainer";
import { IoMdCloseCircle as Close } from "react-icons/io";

const CollapseModificaFaq = ({
  faq,
  indexFaq,
  AggiornaFaq,
  UpdateAzioniUpdate,
  RemoveAzioniFaqUpdate,
  AggiungiAzioniFaqUpdate,
}) => {
  const {
    dominioDelSito,
    setDominioDelSito,
    linkDiretto,
    setLinkDiretto,
    loading,
    setLoading,
    error,
    setError,
    token,
    messageError,
    aggiornaDomanda,
    setAggiornaDomanda,
    aggiornaRisposta,
    setAggiornaRisposta,
    setMessageError,
  } = useContext(StateContext);
  return (
    <div id={`aggiornaFaq-${indexFaq}`} className='collapse'>
      <div key={indexFaq} className='divDomanda py-2'>
        <label htmlFor={`inputDomanda-${indexFaq}`}>Domanda</label>
        <input
          id={`inputDomanda-${indexFaq}`}
          type='text'
          value={aggiornaDomanda || faq.question}
          onChange={(event) => setAggiornaDomanda(event.target.value)}
          className='w-100 p-2 mt-2 rounded inputSitoWeb'
        />
      </div>
      <div className='divRisposta py-2'>
        <label htmlFor={`inputRisposta-${indexFaq}`}>Risposta</label>
        <textarea
          id={`inputRisposta-${indexFaq}`}
          placeholder={faq.answer}
          value={aggiornaRisposta || faq.answer}
          onChange={(event) => setAggiornaRisposta(event.target.value)}
          onClick={(event) => setAggiornaRisposta(event.target.value)}
          className='w-100 p-2 mt-2 rounded inputSitoWeb'
        />
      </div>
      <button
        onClick={AggiungiAzioniFaqUpdate}
        className='btn bg-verde text-white w-25 my-3'
      >
        + Aggiungi Azione
      </button>
      {faq.actions &&
        faq.actions.map((azione, index) => {
          return (
            <div
              className='col-12 gap-2 d-flex flex-column align-items-center justify-content-center my-2'
              key={index}
            >
              <div className='d-flex justify-content-between align-items-center w-100'>
                <h6>Azione #{index + 1}</h6>
                <a
                  className='fs-2 text-danger'
                  style={{ cursor: "pointer" }}
                  onClick={() => RemoveAzioniFaqUpdate(index)}
                >
                  <Close />
                </a>
              </div>
              <input
                type='text'
                placeholder='Inserisci Etichetta'
                value={azione.etichetta}
                onChange={(e) =>
                  UpdateAzioniUpdate(
                    indexFaq,
                    index,
                    e.target.value,
                    "etichetta"
                  )
                }
                className='w-100 p-2 rounded inputDomandaQA'
              />
              <select
                className='form-select p-2'
                aria-label='Select'
                value={azione.type}
                onChange={(e) =>
                  UpdateAzioniUpdate(indexFaq, index, e.target.value, "type")
                }
              >
                <option value='default'>Nessuna Opzione Selezionata</option>
                <option value='talk'>Talk</option>
                <option value='link'>Link</option>
              </select>
              {azione.type === "link" ? (
                <input
                  type={"text"}
                  className='w-100 p-2 rounded form-control'
                  placeholder='Es: https://www.sito.it/'
                  value={azione.content}
                  onChange={(e) =>
                    UpdateAzioniUpdate(
                      indexFaq,
                      index,
                      e.target.value,
                      "content"
                    )
                  }
                />
              ) : (
                <textarea
                  type={"text"}
                  className='w-100 p-2 rounded form-control'
                  placeholder="Inserisci la domanda da fare all'assistente"
                  rows='5'
                  value={azione.content}
                  onChange={(e) =>
                    UpdateAzioniUpdate(
                      indexFaq,
                      index,
                      e.target.value,
                      "content"
                    )
                  }
                />
              )}
              <hr className='w-100'></hr>
            </div>
          );
        })}

      <div className='row d-flex flex-fill h-auto my-3'>
        <div className='col-12 gap-2 d-flex align-items-end justify-content-end'>
          <button
            className={`btn text-white bg-green-second`}
            onClick={() =>
              AggiornaFaq(faq.code, faq.answer, faq.question, faq.actions)
            }
          >
            Aggiorna
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollapseModificaFaq;
