import React, { useContext } from "react";
import { HiOutlineMail as Email } from "react-icons/hi";
import { RiSuitcaseLine as Valigetta } from "react-icons/ri";
import { MdOutlineFactory as Company } from "react-icons/md";
import { BsPersonVcard as IdentityCard } from "react-icons/bs";
import { BiConversation as Conversation } from "react-icons/bi";
import {
  AiOutlinePhone as Phone,
  AiOutlineCalendar as Calendar,
} from "react-icons/ai";
import { BsGlobe as Globo } from "react-icons/bs";
import Moment from "react-moment";
import { StateContext } from "../../StateContainer";
import { useNavigate } from "react-router-dom";

const ElencoLeadMobile = ({ leads }) => {
  const { setConversationCode } = useContext(StateContext);
  const navigate = useNavigate();
  const VaiAlloStorico = (item) => {
    let conversationCode = item.conversation ? item.conversation.code : "";
    setConversationCode(conversationCode);
    navigate(
      `/progetti/modifica-progetto/${item.project.code}/test-assistente`
    );
  };

  return leads.length === 0 ? (
    <div className='col-12 my-3 text-center'>
      <p> Nessun Lead è stato trovato per il progetto selezionato</p>
    </div>
  ) : (
    leads.map((lead, index) => {
      return (
        <div className='card mb-4 px-0' key={index}>
          <div className='row g-0'>
            <div
              className='col-3 col-md-2 d-flex align-items-center justify-content-center listLeadsMobile
          fs-1'
            >
              <IdentityCard />
            </div>
            <div className='col'>
              <div className='card-body'>
                <p className='d-flex gap-2 align-items-center date-leadList-mobile mb-2'>
                  <Calendar />
                  <Moment format='DD/MM/YYYY'>
                    {lead.created.date || "-"}
                  </Moment>
                </p>
                <h3 className='text-capitalize'>{lead.name || "Utente"}</h3>
                <p className='d-flex gap-2 align-items-center'>
                  <Email />
                  {lead.email || "-"}
                </p>
                <p className='d-flex gap-2 align-items-center'>
                  <Valigetta />
                  {lead.jobTitle || "-"}
                </p>
                <p className='d-flex gap-2 align-items-center'>
                  <Phone />
                  {lead.phone || "-"}
                </p>
                <p className='d-flex gap-2 align-items-center'>
                  <Company />
                  {lead.company || "-"}
                </p>
                <p className='d-flex gap-2 align-items-center'>
                  <Globo />
                  {(lead.conversation && lead.conversation.link) || "-"}
                </p>
              </div>
            </div>
            <div className='col-2 fs-1 d-flex align-items-center justify-content-center'>
              {lead.conversation && (
                <button
                  className='btn float-end button-conversation-mobile'
                  onClick={() => VaiAlloStorico(lead)}
                >
                  <Conversation />
                </button>
              )}
            </div>
          </div>
        </div>
      );
    })
  );
};

export default ElencoLeadMobile;
