import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { googleLogout } from "@react-oauth/google";

function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    if (!token || !tokenExpiration) {
      return;
    }

    setAuthenticated(true);
  }, []);

  // function login(token) {
  //   localStorage.setItem("token", token);

  //   axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASE_URL}/user/detail`,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //   }).then((response) => {
  //     console.log(response);
  //     let url = `${process.env.REACT_APP_BASE_URL}`;
  //     let cacheName = "userInfo";
  //     const addDataIntoCache = (cacheName, url, response) => {
  //       // Converting our response into Actual Response form
  //       const data = new Response(JSON.stringify(response));
  //       if ("caches" in window) {
  //         // Opening given cache and putting our data into it
  //         caches.open(cacheName).then((cache) => {
  //           cache.put(url, data);
  //           alert("Data Added into cache!");
  //         });
  //       }
  //     };
  //     addDataIntoCache(cacheName, url, response);
  //   });
  //   const redirectUrl = sessionStorage.getItem("redirectUrl");
  //   if (redirectUrl) {
  //     sessionStorage.removeItem("redirectUrl");
  //     window.location.assign(redirectUrl);
  //   } else {
  //     window.location.assign("/dashboard");
  //   }
  // }
  function login(token) {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/user/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        const data = response.data.response.user;
        Cookies.set("userData", JSON.stringify(data), {
          expires: 0.5,
        });
        localStorage.setItem("token", token);
        const redirectUrl = sessionStorage.getItem("redirectUrl");
        if (redirectUrl) {
          sessionStorage.removeItem("redirectUrl");
          window.location.assign(redirectUrl);
        } else {
          window.location.assign("/dashboard");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function addDataIntoCache(cacheName, url, data) {
    try {
      const cache = await caches.open(cacheName);
      const cachedResponse = await cache.match(url);

      if (!cachedResponse) {
        // Serializza i dati come stringa JSON prima di memorizzarli nella cache
        cache.put(url, data);
        console.log("Dati aggiunti nella cache!");
      } else {
        console.log("I dati sono già presenti nella cache.");
      }
    } catch (error) {
      console.error("Errore durante l'aggiunta dei dati nella cache:", error);
    }
  }

  function logout() {
    // localStorage.removeItem("token");
    // localStorage.removeItem("tokenExpiration");
    // localStorage.removeItem("nextRotationAttemptTs");
    // Cookies.remove("userData");
    // setAuthenticated(false);
    // caches.delete("userInfo");
    googleLogout();
    window.location.href = "/login";
  }

  return { authenticated, login, logout };
}

export default useAuth;
