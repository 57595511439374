import React, { useContext } from "react";
import { StateContext } from "../../StateContainer";
import ChatPreview from "../ChatPreview";
import { IoMdClose as Close } from "react-icons/io";

const VisualizzaAnteprimaChat = ({
  progetto,
  currentFileImage,
  AvatarPink,
}) => {
  const { visualizzaAnteprimaChat, setVisualizzaAnteprimaChat } =
    useContext(StateContext);
  return (
    <div
      className={`overlay ${!visualizzaAnteprimaChat && "d-none"}`}
      id='overlayDataModal'
    >
      <div className='w-100 text-end'>
        <button
          type='button'
          className='btn fs-1 text-white'
          onClick={() => setVisualizzaAnteprimaChat(false)}
        >
          <Close></Close>
        </button>
      </div>
      <div className='chat-preview-mobile w-100'>
        <ChatPreview
          progetto={progetto}
          currentFileImage={currentFileImage}
          AvatarPink={AvatarPink}
        ></ChatPreview>
      </div>
    </div>
  );
};

export default VisualizzaAnteprimaChat;
