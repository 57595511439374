import React from "react";
import { BsFillCheckCircleFill as Corretto } from "react-icons/bs";

const ModalSuccess = ({ title, message, FunctionRemoveModal }) => {
  const RemoveUpdateDataModal = () => {
    FunctionRemoveModal(false);
  };

  let modalSuccess = document.getElementById("modalSuccess");
  if (modalSuccess) {
    const transitionEndHandler = () => {
      // Rimuovi l'ascoltatore dell'evento transitionend
      modalSuccess.removeEventListener("transitionend", transitionEndHandler);
    };

    // Aggiungi l'ascoltatore dell'evento transitionend
    modalSuccess.addEventListener("transitionend", transitionEndHandler);

    setTimeout(() => {
      modalSuccess.style.top = "0px";
      modalSuccess.style.height = "100%";
      modalSuccess.style.transition = "all 250ms";
    }, 400);
  }

  return (
    <div className='overlay d-flex align-items-center ' id='overlayDataModal'>
      <div className='modal' style={{ display: "block" }} id='modalSuccess'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title d-flex align-items-center gap-2'>
                <Corretto className='text-success fs-4' /> {title}
              </h5>
            </div>
            <div className='modal-body'>
              <p>{message}</p>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={RemoveUpdateDataModal}
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSuccess;
