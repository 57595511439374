import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { TbDotsVertical as Dots, TbH1 } from "react-icons/tb";
import { StateContext } from "../StateContainer";
import PoweredBy from "../../img/TailorGPTOriz.svg";
import { IoMdSend as Invia } from "react-icons/io";
import AvatarPink from "../../img/avatarPink.png";
import FormGetUserDataPreview from "./FormGetUserDataPreview";

const IframeChatExample = ({
  title,
  allProjectInformation,
  progetto,
  currentFileImage,
  activePanel,
}) => {
  const [caricamento, setCaricamento] = useState(false);
  const cardBodyRef = useRef(null);

  const {
    setSendLeadTextExample,
    sendLeadTextExample,
    primoMessaggioBot,
    assistantName,
    privacyLink,
    primaryColor,
    secondaryColor,
    coloreMessaggi,
    queries,
  } = useContext(StateContext);

  // Colore esadecimale

  var hexPrimaryColor = null;
  var primaryRed = null;
  var primaryGreen = null;
  var primaryBlue = null;

  var primaryRGBA = null;

  if (progetto || primaryColor) {
    hexPrimaryColor = progetto.primaryColor || primaryColor;

    if (hexPrimaryColor) {
      // Estrai i componenti di rosso, verde, blu dall'hex
      primaryRed = parseInt(hexPrimaryColor.slice(1, 3), 16);
      primaryGreen = parseInt(hexPrimaryColor.slice(3, 5), 16);
      primaryBlue = parseInt(hexPrimaryColor.slice(5, 7), 16);

      primaryRGBA = `rgba(${primaryRed}, ${primaryGreen}, ${primaryBlue}, 0.1)`;
    }
  }

  var hexSecondaryColor = null;
  var secondaryRed = null;
  var secondaryGreen = null;
  var secondaryBlue = null;

  var secondaryRGBA = null;

  let alpha = 0.1; // Opacità
  let secAlpha = 0.9; // Opacità
  let red = null;
  let green = null;
  let blue = null;
  let redHex = null;
  let greenHex = null;
  let blueHex = null;
  let hexColorPerSVG = null;

  if (progetto || secondaryColor) {
    hexSecondaryColor = progetto.secondaryColor || secondaryColor;

    if (hexSecondaryColor) {
      // Estrai i componenti di rosso, verde, blu dall'hex
      secondaryRed = parseInt(hexSecondaryColor.slice(1, 3), 16);
      secondaryGreen = parseInt(hexSecondaryColor.slice(3, 5), 16);
      secondaryBlue = parseInt(hexSecondaryColor.slice(5, 7), 16);

      secondaryRGBA = `rgba(${secondaryRed}, ${secondaryGreen}, ${secondaryBlue}, ${alpha})`;

      red = Math.round(secondaryRed + (255 - secondaryRed) * secAlpha);
      green = Math.round(secondaryGreen + (255 - secondaryGreen) * secAlpha);
      blue = Math.round(secondaryBlue + (255 - secondaryBlue) * secAlpha);

      redHex = red.toString(16).padStart(2, "0");
      greenHex = green.toString(16).padStart(2, "0");
      blueHex = blue.toString(16).padStart(2, "0");

      hexColorPerSVG = `#${redHex}${greenHex}${blueHex}`;
    }
  }

  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredButton(index);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const InviaEsempioMessaggioLead = (query) => {
    let exampleUserMessage = document.getElementById("exampleUserMessage");
    exampleUserMessage.classList.remove("hideMessageSendedByLead");
    exampleUserMessage.classList.add("showMessageSendedByLead");
    setSendLeadTextExample(query);
    setTimeout(() => {
      exampleUserMessage.classList.remove("showMessageSendedByLead");
      setTimeout(() => {
        exampleUserMessage.classList.add("hideMessageSendedByLead");
      }, 5000);
    }, 250);
  };

  return (
    <div className='card card-storico-conversazioni position-relative'>
      <FormGetUserDataPreview
        progetto={progetto}
        activePanel={activePanel}
      ></FormGetUserDataPreview>
      <div
        ref={cardBodyRef}
        style={{ overflowY: "scroll", overflowX: "hidden" }}
        className='card-body containerMessagesChatbot position-relative'
      >
        <div className='chat-conversation py-3'>
          <ul
            className='list-unstyled mb-0 chat-conversation-message px-0'
            data-simplebar
            style={{ fontSize: "0.875rem" }}
          >
            <div>
              <li
                className='pb-2'
                id='primoMessaggio'
                style={{ color: `${coloreMessaggi}` }}
              >
                <div className='d-flex align-items-start gap-2'>
                  <div
                    style={{
                      minHeight: "16px",
                      minWidth: "16px",
                      borderRadius: "100%",
                      background: `url(${
                        currentFileImage === null
                          ? (progetto[0] && progetto[0].chatbotIcon) ||
                            AvatarPink
                          : currentFileImage
                      }) center center / cover no-repeat`,
                    }}
                    className='bg-transparent'
                  ></div>
                  <div>
                    <h5 className='font-size-14 conversation-name'>
                      <a href='#' className='text-dark text-decoration-none'>
                        {assistantName}
                      </a>{" "}
                    </h5>

                    <div
                      className='conversation-list'
                      style={{ maxWidth: "400px" }}
                    >
                      {progetto && primoMessaggioBot
                        ? primoMessaggioBot
                            .split("\n")
                            .map((paragraph, index) => (
                              <div className='ctext-wrap mt-2 mb-0' key={index}>
                                <div
                                  className='ctext-wrap-content m-0'
                                  style={{
                                    background: `${primaryRGBA}`,
                                    maxWidth: "300px",
                                  }}
                                >
                                  <p
                                    className='mb-0'
                                    style={{
                                      color: progetto
                                        ? progetto.userMessageColor
                                        : "",
                                    }}
                                  >
                                    {paragraph}
                                  </p>
                                </div>
                              </div>
                            ))
                        : ""}
                    </div>
                  </div>
                </div>
              </li>

              <li
                className={`right ${
                  sendLeadTextExample === "" || queries.length === 0
                    ? "hideMessageSendedByLead"
                    : ""
                }`}
                id='exampleUserMessage'
              >
                <div className='conversation-list'>
                  <div className='ctext-wrap m-0'>
                    <div
                      className='ctext-wrap-content m-0 text-white'
                      style={{ background: `rgba(83, 83, 83, 1)` }}
                    >
                      <p className='mb-0 text-white'>{sendLeadTextExample}</p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div
          className='position-absolute w-100 text-center p-3'
          style={{ bottom: "0px" }}
        >
          {progetto
            ? queries.map((query, index) => (
                <button
                  key={index} // Assicurati di aggiungere una chiave unica per ogni pulsante
                  className='btn div-advice-buttons mx-2 font-size-14'
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => InviaEsempioMessaggioLead(query.question)}
                  style={{
                    backgroundColor:
                      hoveredButton === index
                        ? progetto && secondaryColor // colore verde
                        : "white",
                  }}
                >
                  <p className='m-0'>{query.label}</p>
                </button>
              ))
            : ""}
        </div>
      </div>

      <div
        className={`py-4 card-footer chat-input-section d-flex flex-fill align-items-start w-100 justify-content-center bg-white footer-chat 
                          `}
        style={{ zIndex: "999" }}
      >
        <div className='footer-row row w-100 d-flex justify-content-center m-0'>
          <div
            style={{
              backgroundColor: `${secondaryRGBA}`,
              height: "50px",
              borderTop: `${
                progetto &&
                `1px ${progetto.secondaryColor || secondaryColor} solid`
              }`,
              borderBottom: `${
                progetto &&
                `1px ${progetto.secondaryColor || secondaryColor} solid`
              }`,
            }}
            className='w-100 d-flex justify-content-center p-0 align-items-center m-0 px-4'
          >
            <div className='w-100 '>
              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control search-bar w-100 bg-transparent border-0 shadow-none px-0 disable'
                  style={{ outline: "none" }}
                  placeholder={`Invia un messaggio`}
                  disabled='disabled'
                />
              </div>
            </div>

            <div className='col-auto d-flex align-items-center d-none d-md-block'>
              <button
                type='submit'
                className={`btn chat-send w-md waves-effect waves-light d-flex align-items-center justify-content-center border-0 disabled
                `}
                style={{
                  backgroundColor: `${
                    (progetto && progetto.secondaryColor) || secondaryColor
                  }`,

                  color: `${hexColorPerSVG}`,

                  border: "1px grey solid",
                }}
              >
                <span className='d-none d-sm-inline-block me-2'>Invia</span>

                <Invia
                  fill={`${hexColorPerSVG}`}
                  stroke={`${hexColorPerSVG}`}
                />
              </button>
            </div>

            <div className='col-auto d-flex align-items-center d-block d-md-none'>
              <button
                type='submit'
                className='btn chat-send waves-effect waves-light d-flex align-items-center justify-content-center border-0 rounded-circle p-0 fs-4'
                style={{
                  backgroundColor: `${
                    (progetto && progetto.secondaryColor) || secondaryColor
                  }`,
                  height: "40px",
                  width: "40px",

                  color: `${hexColorPerSVG}`,

                  border: "1px grey solid",
                }}
              >
                <Invia
                  fill={`${hexColorPerSVG}`}
                  stroke={`${hexColorPerSVG}`}
                />
              </button>
            </div>
          </div>

          <div
            className={`w-100 d-flex align-items-start my-1 px-4 justify-content-between`}
          >
            {progetto && progetto.hideTailorGPTLogo ? (
              ""
            ) : (
              <div className='my-1'>
                <a
                  href='https://tailorgpt.ai/'
                  target='_blank'
                  className='text-decoration-none'
                >
                  <img
                    style={{
                      width: "auto",
                      height: "1.3vw",
                      minHeight: "15px",
                    }}
                    src={`${PoweredBy}`}
                    alt=''
                    className='img-fulgeas-chat'
                  />
                </a>
              </div>
            )}
            {progetto &&
              (progetto.privacy || privacyLink ? (
                <div className='my-2 d-flex align-items-center'>
                  <a
                    className='font-size-11'
                    style={{ color: "#535353" }}
                    href={`${progetto.privacy || privacyLink}`}
                    target='_blank'
                  >
                    Privacy
                  </a>
                </div>
              ) : (
                ""
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IframeChatExample;
