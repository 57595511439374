import React, { useContext, useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { Link } from "react-router-dom";
import { StateContext } from "./components/StateContainer";
import axios from "axios";
import PaginationLeads from "./components/ElencoLead/PaginateItems";
import { AiOutlinePlusCircle as Aggiungi } from "react-icons/ai";
import FiltraLeads from "./components/ElencoLead/FiltraLeads";
import Loading from "./components/Loading";
import ElencoLeadMobile from "./components/ElencoLead/Mobile/ElencoLeadMobile";

const ElencoLead = () => {
  const { token, leadTable, setLeadTable } = useContext(StateContext);
  const [showFilterFormLeads, setShowFilterFormLeads] = useState(false);
  const [filterByProjectCode, setFilterByProjectCode] = useState("");
  const [listOfProjects, setListOfProjects] = useState([]);
  const [loadingListOfLeads, setLoadingListOfLeads] = useState(false);

  useEffect(() => {
    setLoadingListOfLeads(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/lead-list`,
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: filterByProjectCode,
        limit: "500",
        offset: "",
      },
    })
      .then((res) => {
        setLoadingListOfLeads(false);
        setLeadTable(res.data.response.leads);
      })
      .catch((err) => console.log(err));
  }, [filterByProjectCode]);

  return (
    <div className='App'>
      <div id='layout-wrapper'>
        <Navbar />
        <Sidebar />
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              <div className='row px-2'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12 px-0 px-lg-2'>
                      <div className='page-title-box d-flex align-items-center justify-content-between'>
                        <h4 className='mb-0'>Elenco Lead</h4>

                        <div className='page-title-right'>
                          <ol className='breadcrumb m-0'>
                            <li className='breadcrumb-item'>
                              <Link
                                to='/dashboard'
                                className='text-decoration-none text-dark'
                              >
                                Home
                              </Link>
                            </li>
                            <li className='breadcrumb-item text-dark'>
                              Elenco Lead
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* FiltraLeads */}
                <div className='col-12'>
                  <FiltraLeads
                    showFilterFormLeads={showFilterFormLeads}
                    setShowFilterFormLeads={setShowFilterFormLeads}
                    setFilterByProjectCode={setFilterByProjectCode}
                    filterByProjectCode={filterByProjectCode}
                    leadTable={leadTable}
                    listOfProjects={listOfProjects}
                    setListOfProjects={setListOfProjects}
                  ></FiltraLeads>
                </div>

                {/* <TableElencoLead leads={leadTable}></TableElencoLead> */}
                {loadingListOfLeads ? (
                  <div className='row'>
                    <div className='col-12 text-center loading-form'>
                      <p className='m-0'>
                        <Loading />
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <PaginationLeads itemsPerPage={10} items={leadTable} />
                    <div className='col-12'>
                      <div className='row d-block d-lg-none'>
                        <ElencoLeadMobile leads={leadTable} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElencoLead;
