import React, { useContext, useEffect } from "react";
import { SlDocs as IconaDocumenti } from "react-icons/sl";
import { TfiWorld as IconaSito } from "react-icons/tfi";
import { BsPencilFill as IconaTestoLibero } from "react-icons/bs";
import { BsFillPatchQuestionFill as IconaQA } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft as BackArrow } from "react-icons/ai";
import { StateContext } from "../StateContainer";

const MenuCaricamentoConteuti = ({ progetto, id, CambiaSezioneMobile }) => {
  const { sectionMobile, setSectionMobile } = useContext(StateContext);

  const BackToGestioneProgetti = () => {
    const menuGestioneProgetto = document.getElementById("gestione-progetto");
    const caricamentoContenuti = document.getElementById(
      "menuCaricamentoContenuti"
    );
    menuGestioneProgetto.style.display = "block";
    setTimeout(() => {
      menuGestioneProgetto.style.left = "0px";
      caricamentoContenuti.style.left = "2000px";

      // Imposta il display su "none" dopo il ritardo di 50 millisecondi
      setTimeout(() => {
        caricamentoContenuti.style.display = "none";
      }, 100);
    }, 100);
  };

  const menuCaricamento = [
    {
      "id": 1,
      "title": "Sito Web",
      "icon": <IconaSito />,
      "navigateTo": "#sitoWeb",
    },
    {
      "id": 2,
      "title": "Documenti",
      "icon": <IconaDocumenti />,
      "navigateTo": "#documenti",
    },
    {
      "id": 3,
      "title": "Q & A",
      "icon": <IconaQA />,
      "navigateTo": "#questionAndAnswers",
    },
    {
      "id": 4,
      "title": "Testo Libero",
      "icon": <IconaTestoLibero />,
      "navigateTo": "#testoLibero",
    },
    {
      "id": 5,
      "title": "Database",
      "icon": <IconaTestoLibero />,
      "navigateTo": "#database",
    },
  ];
  return (
    <div
      className='row justify-content-center w-100 mt-3'
      id='menuCaricamentoContenuti'
    >
      <div className='col-12 px-4 d-flex align-items-center justify-content-between'>
        <Link
          className='text-decoration-none text-capitalize d-flex align-items-center gap-3 fs-5 text-dark'
          onClick={BackToGestioneProgetti}
        >
          <p className='m-0'>
            <BackArrow />
            Torna alla Gestione Contenuti
          </p>
        </Link>

        <Link
          className='btn bg-orange text-white rounded-4 my-3 text-decoration-none text-uppercase'
          to={`/progetti/modifica-progetto/${
            progetto.code || id
          }/test-assistente`}
          data-bs-dismiss='offcanvas'
        >
          Prova
        </Link>
      </div>
      <div className='col-12 mt-3 mb-1 text-center'>
        <p className='m-0 text-uppercase fw-bold font-size-20'>
          Caricamento Contenuti
        </p>
      </div>
      {menuCaricamento.map((section) => {
        return (
          <div
            className='col-12 bg-green-second CaricamentoMenuSection mt-3 d-flex align-items-center px-5 position-relative'
            key={section.id}
          >
            <div className='text-decoration-none text-uppercase text-white d-flex align-items-center gap-3 fs-4'>
              {section.icon}
              <p className='m-0'>{section.title}</p>
            </div>

            <Link
              className='stretched-link'
              to={`/progetti/modifica-progetto/${progetto.code || id}`}
              data-bs-dismiss='offcanvas'
              onClick={() => CambiaSezioneMobile(section.navigateTo)}
            ></Link>
          </div>
        );
      })}
    </div>
  );
};

export default MenuCaricamentoConteuti;
