import React, { useContext } from "react";
import { StateContext } from "../../../StateContainer";

const CollapseVisualizzaTestoDoc = ({ index }) => {
  const {
    textOfTheDocument,
    setTextOfTheDocument,
    currentIndexDocument,
    setCurrentIndexDocument,
  } = useContext(StateContext);

  return (
    <div
      id={`aggiornaDocument-${index}`}
      className={`border-0 ${
        currentIndexDocument === index ? "collapse show collapsing" : "collapse"
      }`}
    >
      <div className='divRisposta py-2'>
        <label htmlFor={`inputRisposta-${index}`}>Testo Acquisito</label>
        <textarea
          disabled='true'
          id={`inputRisposta-${index}`}
          // placeholder={faq.answer}
          value={textOfTheDocument}
          onChange={(event) => setTextOfTheDocument(event.target.value)}
          className='w-100 p-2 mt-2 rounded inputSitoWeb'
          style={{ minHeight: "400px" }}
        />
      </div>
    </div>
  );
};

export default CollapseVisualizzaTestoDoc;
