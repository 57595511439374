import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../StateContainer";
import { useParams } from "react-router-dom";
import { BsPlusCircleFill as Aggiungi } from "react-icons/bs";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { IoRefresh as Refresh } from "react-icons/io5";
import Moment from "react-moment";
import "moment/locale/it";
import axios from "axios";
import ModalNotificationError from "../Modals/ModalNotificationError";
import TestiLiberiCaricatiMobile from "./Mobile/TestiLiberiCaricatiMobile";

const TestoLiberoCarica = ({ dettagliProgetto }) => {
  const {
    dominioDelSito,
    setDominioDelSito,
    linkDiretto,
    setLinkDiretto,
    loading,
    setLoading,
    error,
    aggiornamentoDatiProgetto,
    setError,
    token,
    setMessageError,
    messageError,
  } = useContext(StateContext);

  const { id } = useParams();
  const [showTextForm, setShowTextForm] = useState(false);
  const [titleOfFreeText, setTitleOfFreeText] = useState("");
  const [contentOfFreeText, setContentOfFreeText] = useState("");
  const [isUpdatingFreeText, setIsUpdatingFreeText] = useState(false);
  const [isDeletingFreeText, setIsDeletingFreeText] = useState(false);
  const [isAddingFreeText, setIsAddingFreeText] = useState(false);
  const [codeFreeText, setCodeFreeText] = useState("");
  const [isOpenFormUpdate, setIsOpenFormUpdate] = useState(false);
  const [erroreCaricamentoFreeText, setErroreCaricamentoFreeText] =
    useState(false);

  const AddFreeText = () => {
    setIsOpenFormUpdate(false);
    setIsAddingFreeText(true);

    if (titleOfFreeText === "" || contentOfFreeText === "") {
      setIsUpdatingFaq(false);
      setMessageError("Devi compilare il Titolo e Testo per caricare i dati.");
      setIsAddingFreeText(true);
      setErroreCaricamentoFreeText(true);
      setIsUpdatingFreeText(false);
      setIsAddingFreeText(false);
      return;
    }

    const apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/add_free_text`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
      body: JSON.stringify({
        projectCode: id,
        token: token,
        title: titleOfFreeText,
        text: contentOfFreeText,
      }),
    };

    fetch(apiURL, requestOptions)
      .then((response) => {
        setIsUpdatingFreeText(false);
        setIsAddingFreeText(false);
        setShowTextForm(false);
        setContentOfFreeText("");
        setTitleOfFreeText("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EditFreeText = (codice, titolo, testo) => {
    setIsOpenFormUpdate(true);
    setShowTextForm(true);
    setCodeFreeText(codice);
    setTitleOfFreeText(titolo);
    setContentOfFreeText(testo);
  };

  const UpdateFreeText = () => {
    setIsUpdatingFreeText(true);
    const apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/update_freetext`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
      body: JSON.stringify({
        projectCode: id,
        code: codeFreeText,
        token: token,
        title: titleOfFreeText,
        text: contentOfFreeText,
      }),
    };

    fetch(apiURL, requestOptions)
      .then((response) => {
        setIsUpdatingFreeText(false);
        setShowTextForm(false);
        setIsAddingFreeText(false);
        setIsOpenFormUpdate(false);
        setTitleOfFreeText("");
        setContentOfFreeText("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteFreeText = (code) => {
    setIsDeletingFreeText(true);
    const apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/delete_freetext`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
      body: JSON.stringify({
        projectCode: id,
        code: code,
        token: token,
      }),
    };

    fetch(apiURL, requestOptions)
      .then((response) => {
        setIsDeletingFreeText(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [isUpdatingFaq, setIsUpdatingFaq] = useState(false);

  const [faqRecived, setFaqRecived] = useState([]);

  useEffect(() => {
    setDominioDelSito("");
    setLoading(true);

    // Funzione per la chiamata API
    const fetchData = () => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          projectCode: id,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            window.location.replace("/login");
          } else {
            setLoading(false);
            if (response.data.response.links !== faqRecived) {
              setFaqRecived(response.data.response.freetexts);
            }
            setError("");
          }
        })
        .catch((err) => setError(err));
    };

    // Esegui l'effetto una sola volta all'inizio
    if (!isUpdatingFreeText && !isAddingFreeText && !isDeletingFreeText) {
      fetchData();
    } else {
      // Esegui l'effetto ogni 5 secondi
      const interval = setInterval(fetchData, 5000);

      // Pulisci l'intervallo quando l'effetto viene rimosso
      return () => clearInterval(interval);
    }
  }, [
    isUpdatingFreeText,
    isAddingFreeText,
    isDeletingFreeText,
    aggiornamentoDatiProgetto,
  ]);

  const ContenutoTotaleTestoLibero = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 3000) {
      // Se la lunghezza è minore o uguale a mille, aggiorna il testo normalmente
      setContentOfFreeText(inputValue);
    } else {
      // Se la lunghezza supera mille, taglia il testo ai primi mille caratteri
      setContentOfFreeText(inputValue.slice(0, 3000));
    }
  };

  const ContenutoTotaleTitoloTestoLibero = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 40) {
      // Se la lunghezza è minore o uguale a mille, aggiorna il testo normalmente
      setTitleOfFreeText(inputValue);
    } else {
      // Se la lunghezza supera mille, taglia il testo ai primi mille caratteri
      setTitleOfFreeText(inputValue.slice(0, 40));
    }
  };

  return (
    <>
      <div id='modifica-testo-libero'></div>
      <div className='container position-relative h-100 d-flex flex-column card_content'>
        {!showTextForm ? (
          <div className='row my-5'>
            <div className='col-12 d-flex align-items-center justify-content-center text-center'>
              <button
                href=''
                role='button'
                className='btn bg-green-second text-white text-center'
                onClick={() => setShowTextForm(true)}
              >
                + Aggiungi del Testo Libero
              </button>
            </div>
            <div className='col-12 d-flex align-items-center text-center justify-content-center'>
              <p className='m-0 my-3 text-muted'>
                Fai click sul bottone, nella casella di testo potrai incollare
                del testo, oppure scrivere direttamente.
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className=''>
              <label>Titolo</label>

              <div className='d-flex align-items-center justify-content-between gap-2'>
                <p className='text-muted my-1'>
                  Il titolo serve solo come riferimento interno, non viene usato
                  per la risposta dell'assistente
                </p>
                <p
                  className={`m-0 ${
                    titleOfFreeText.length === 40 ? "text-danger" : ""
                  }`}
                >
                  {titleOfFreeText.length === 40
                    ? "Limite Massimo Raggiunto: "
                    : ""}
                  {titleOfFreeText.length} / 40
                </p>
              </div>
              <input
                id={`inputTitolo`}
                type='text'
                placeholder='Scrivi il titolo di riferimento'
                className='w-100 p-2 mt-2 rounded inputTitolo'
                value={titleOfFreeText}
                onChange={(event) => ContenutoTotaleTitoloTestoLibero(event)}
              />
            </div>
            <div className='my-4 d-grid'>
              <div className='d-flex justify-content-between gap-2'>
                <label htmlFor={`inputTesto`}>Testo</label>
                <p
                  className={`m-0 ${
                    contentOfFreeText.length === 3000 ? "text-danger" : ""
                  }`}
                >
                  {contentOfFreeText.length === 3000
                    ? "Limite Massimo Raggiunto: "
                    : ""}
                  {contentOfFreeText.length} / 3000
                </p>
              </div>
              <textarea
                style={{ height: "auto", minHeight: "300px" }}
                id={`inputTesto`}
                placeholder='Scrivi o copia incolla il testo'
                className='w-100 p-2 my-2 rounded inputTesto'
                value={contentOfFreeText}
                onChange={(event) => ContenutoTotaleTestoLibero(event)}
              />
            </div>
          </>
        )}

        {!showTextForm ? (
          ""
        ) : isOpenFormUpdate ? (
          <div className='col-12 d-flex justify-content-end'>
            <div>
              <a
                className={`btn text-white bg-green-second ${
                  isUpdatingFreeText ? "disabled" : ""
                }`}
                onClick={UpdateFreeText}
              >
                Aggiorna
              </a>
            </div>
          </div>
        ) : (
          <div className='col-12 d-flex justify-content-end'>
            <div>
              <button
                className={`btn text-white bg-green-second ${
                  isAddingFreeText ? "disabled" : ""
                }`}
                onClick={AddFreeText}
              >
                Salva
              </button>
            </div>
          </div>
        )}

        <div className='col-12 my-4'>
          {isUpdatingFaq ? (
            <div
              className='alert alert-warning d-flex align-items-center gap-2'
              role='alert'
            >
              <div className='spinner-border fs-6' role='status'>
                <span className='sr-only'></span>
              </div>
              Sto caricando le FAQ, attendere un momento ...
            </div>
          ) : (
            ""
          )}
          <table className='table rounded d-none d-xl-table'>
            <thead className='bg-secondary text-white'>
              <tr>
                <th scope='col'></th>
                <th scope='col'>Titolo</th>
                <th scope='col'>Testo</th>
                <th scope='col'>Data ultima interazione</th>
                <th scope='col'>Numero di Caratteri</th>
                <th scope='col'>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {faqRecived.length === 0 ? (
                <tr className='text-center'>
                  <td colSpan={6} className='py-4'>
                    Non hai ancora inserito del testo libero
                  </td>
                </tr>
              ) : (
                faqRecived.map((freetext, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <th scope='row' className='align-middle'>
                          {index + 1}
                        </th>

                        <td className='align-middle'>{freetext.title}</td>
                        <td className='align-middle'>
                          {freetext.text.length >= 30
                            ? freetext.text.substring(0, 30) + " ..."
                            : freetext.text}
                        </td>

                        <td className='align-middle'>
                          <Moment format='DD MMMM YYYY, HH:mm' locale='it'>
                            {freetext.updated.date}
                          </Moment>
                        </td>
                        <td className='align-middle'>{freetext.charsCount}</td>

                        <td className='text-center align-middle'>
                          <div className='d-flex gap-2 justify-content-start align-self-center'>
                            <button
                              className='btn btn-danger text-white px-2 fs-5 d-flex align-items-center'
                              title='Elimina Domanda e risposta'
                              onClick={() => DeleteFreeText(freetext.code)}
                            >
                              <Cestino></Cestino>
                            </button>
                            <a
                              className='btn bg-green-second text-white p-0 px-2 fs-5 d-flex align-items-center'
                              title='Aggiorna Domanda e risposta'
                              href={`#modifica-testo-libero`}
                              onClick={() =>
                                EditFreeText(
                                  freetext.code,
                                  freetext.title,
                                  freetext.text
                                )
                              }
                            >
                              <Matita></Matita>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </table>

          <div className='d-block d-xl-none'>
            {faqRecived.length === 0 ? (
              <tr className='text-center'>
                <td colSpan={5} className='py-4'>
                  Nessun sito è stato ancora caricato
                </td>
              </tr>
            ) : (
              faqRecived.map((freetext, index) => {
                return (
                  <TestiLiberiCaricatiMobile
                    freetext={freetext}
                    index={index}
                    EditFreeText={EditFreeText}
                    DeleteFreeText={DeleteFreeText}
                  ></TestiLiberiCaricatiMobile>
                );
              })
            )}
          </div>

          {erroreCaricamentoFreeText ? (
            <ModalNotificationError
              title={"Si è verificato un errore"}
              message={messageError}
              FunctionRemoveModal={setErroreCaricamentoFreeText}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TestoLiberoCarica;
