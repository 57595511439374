import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../StateContainer";
import axios from "axios";
import { useParams } from "react-router-dom";
import "moment/locale/it";

const Database = ({ dettagliProgetto }) => {
  const { id } = useParams();
  const {
    endpointDatabase,
    setEndpointDatabase,
    nomeDatabase,
    setNomeDatabase,
    usernameDatabase,
    setUsernameDatabase,
    passwordDatabase,
    setPasswordDatabase,
    istruzioniDatabase,
    token,
    setError,
    setIstruzioniDatabase,
    setLoading,
  } = useContext(StateContext);

  const AggiornaDatabaseProgetto = (event) => {
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
        mysqlUser: usernameDatabase,
        mysqlEndpoint: endpointDatabase,
        mysqlDatabase: nomeDatabase,
        mysqlPassword: passwordDatabase,
        mysqlPromptInstruction: istruzioniDatabase,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else if (response.status === 200) {
          setLoading(false);
          console.log(response);
        }
      })
      .catch((err) => setError(err));
  };

  return (
    <div className='position-relative h-100 d-flex flex-column '>
      <div className='row'>
        <div className='col-12'>
          <div className=''>
            <label>Endpoint</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci l'indirizzo a cui connettersi.
              </p>
            </div>
            <input
              id={`inputTitolo`}
              type='text'
              placeholder='Scrivi il titolo di riferimento'
              className='w-100 p-2 mt-2 rounded inputTitolo'
              value={endpointDatabase}
              onChange={(event) => setEndpointDatabase(event.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label>Database</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>Inserisci il nome del database</p>
            </div>
            <input
              id={`inputTitolo`}
              type='text'
              placeholder='Scrivi il titolo di riferimento'
              className='w-100 p-2 mt-2 rounded inputTitolo'
              value={nomeDatabase}
              onChange={(event) => setNomeDatabase(event.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label>Username</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci lo username del tuo database
              </p>
            </div>
            <input
              id={`inputTitolo`}
              type='text'
              placeholder='Scrivi il titolo di riferimento'
              className='w-100 p-2 mt-2 rounded inputTitolo'
              value={usernameDatabase}
              onChange={(event) => setUsernameDatabase(event.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label>Password</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci la password del tuo database
              </p>
            </div>
            <input
              id={`inputTitolo`}
              type='text'
              placeholder='Scrivi il titolo di riferimento'
              className='w-100 p-2 mt-2 rounded inputTitolo'
              value={passwordDatabase}
              onChange={(event) => setPasswordDatabase(event.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label>Istruzioni di contesto</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci le istruzioni per addestrare l'assistente a ricercare
                le informazioni corrette nel tuo database.
              </p>
            </div>
            <textarea
              style={{ height: "auto", minHeight: "300px" }}
              id={`inputTesto`}
              placeholder='Scrivi o copia incolla il testo'
              className='w-100 p-2 my-2 rounded inputTesto'
              value={istruzioniDatabase}
              onChange={(event) => setIstruzioniDatabase(event.target.value)}
            />
          </div>

          <button
            className={`btn text-white bg-green-second `}
            onClick={AggiornaDatabaseProgetto}
          >
            Salva
          </button>
        </div>
      </div>
    </div>
  );
};

export default Database;
